<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>participants</label>
          </b-col>

        </b-row>
      </div>
      <b-table
        ref="refParticipantListTable"
        :items="fetchParticipant"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchParticipant.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: user name -->
        <template #cell(name)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.groupName ? item.groupName :
                item.middleName
                  ? `${item.firstName} ${item.middleName} ${item.lastName}`
                  : `${item.firstName} ${item.lastName}`
              }}
            </div>
          </b-media>
        </template>
        <!-- Column: totalPoint -->
        <template #cell(totalPoint)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{
                item.totalPoint
              }}
            </div>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalParticipant"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  // BCardText,
  BCol,

  // BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
// import MatchItem from './MatchItem'

import useParticipantList from './useParticipantList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BMedia,
    // MatchItem,
    vSelect,
    BTable,
    BPagination,
  },
  computed: {
    ...mapState('races/listLeaderboard', ['listPrizes', 'loading', 'total']),
  },
  methods: {
    ...mapActions({
      getListPrizes: 'races/listLeaderboard/getListPrizes',
    }),
    async fetchParticipant() {
      const data = await this.getListPrizes({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: this.$route.params.id,
      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalParticipant = this.total

      return data
    },
    handleSubmitModal() {},
    resetModal() {
    },

  },
  setup() {
    const { showToastError } = useToastHandler()
    const {
      tableColumns,
      perPage,
      currentPage,
      totalParticipant,
      dataMeta,
      perPageOptions,
      refParticipantListTable,
      refetchData,
    } = useParticipantList()
    return {
      formatDate,
      showToastError,
      tableColumns,
      perPage,
      currentPage,
      totalParticipant,
      dataMeta,
      perPageOptions,
      refParticipantListTable,
      refetchData,
    }
  },

}
</script>
