<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card>

      <b-table
        ref="refEventsCompTable"
        :items="listRace"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="meetingsDated.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(id)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold id-column">
              {{ item.id }}
            </div>
          </b-media>
        </template>
        <!-- Column: Name -->
        <template #cell(name)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.track.name }}
            </div>
          </b-media>
        </template>
        <!-- Column: FIRST RACE START TIME -->
        <template #cell(firstRaceStartTime)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatDateTimeLocal(item.races[0].startTime) }}
            </div>
          </b-media>
        </template>
        <!-- Column: LAST RACE START TIME -->
        <template #cell(lastRaceStartTime)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatDateTimeLocal(item.races[item.races.length - 1].startTime) }}
            </div>
          </b-media>
        </template>
        <!-- Column: RACE TYPE -->
        <template #cell(raceType)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.tabIndicator }}
            </div>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRaces"
              :per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BMedia, BTable, BPagination, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { formatDate, formatDateTimeLocal } from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import { arrayProp } from '@/helpers/props'
import gql from 'graphql-tag'
import { addDays } from 'date-fns'
import useEventsList from './useEventsList'

export const GET_LIST_RACE = gql`
  query ($firstRaceFrom: Time!, $to: Time!) {
    meetingsDated(firstRaceFrom: $firstRaceFrom, to: $to) {
      id
      type
      track {
        name
      }
      races {
        number
        id
        status
        name
        startTime
      }
      date
      firstRaceTime
      tabIndicator
      created
    }
  }
`

export default {
  components: {
    BTable,
    BMedia,
    BPagination,
    BRow,
    BCol,
    BCard,
  },
  props: {
    entriesList: arrayProp(),
  },
  data() {
    return {
      meetingsDated: [],
      listRace: [],
    }
  },
  computed: {
    ...mapState('races/listRace', ['tokenBetMaket', 'expireAt']),
    converTimeFrom() {
      const item = this.entriesList[0].startTime
      const day = new Date(item)

      day.setUTCHours(0, 0, 0, 0)

      const startTimeString = day.toISOString()
      return startTimeString
    },
    converTimeTo() {
      const item = this.entriesList[this.entriesList.length - 1].startTime
      const day = addDays(new Date(item), 1)
      day.setUTCHours(0, 0, 0, 0)

      const startTimeString = day.toISOString()
      return startTimeString
    },
  },
  watch: {
    meetingsDated() {
      this.fetchRaces()
    },
  },
  async created() {
    this.time = formatDate(new Date())
    if (new Date().getTime() / 1000 >= this.expireAt) {
      await this.getTokenBetMaker()
    }
    this.$apollo.addSmartQuery('meetingsDated', {
      query: GET_LIST_RACE,
      context: () => ({
        headers: {
          authorization: this.tokenBetMaket,
        },
      }),
      variables() {
        return {
          firstRaceFrom: this.converTimeFrom,
          to: this.converTimeTo,
        }
      },
    })
  },
  methods: {
    ...mapActions({
      getTokenBetMaker: 'races/listRace/getTokenBetMaker',
    }),
    fetchRaces() {
      const data = this.meetingsDated
      this.totalRaces = data.length
      const result = data.filter(itemA => itemA.races.some(race => this.entriesList.some(itemB => itemB.raceId === race.id)))
      this.listRace = result
    },
  },
  setup() {
    const { showToastError } = useToastHandler()
    const {
      tableColumns, currentPage, totalRaces, refEventsCompTable, refetchData,
    } = useEventsList()

    return {
      formatDate,
      formatDateTimeLocal,
      showToastError,
      tableColumns,
      currentPage,
      totalRaces,
      refEventsCompTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.id-column {
  max-width: 150px;
  word-break: break-word;
}
</style>
