import { computed, ref, watch } from '@vue/composition-api'

export default function usePrizesList() {
  // Use toast
  const refPrizesListTable = ref(null)
  const searchValue = ref(null)
  const debounceValue = ref(null)
  const timeout = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'groupId' },
    { key: 'groupName' },
    { key: 'id', label: 'user id' },
    { key: 'name', label: 'user name' },
    { key: 'betid' },
    { key: 'prizeOrder', label: 'prize order' },
    { key: 'prizeValue', label: 'Prize value' },
    { key: 'percent', label: 'Percentage' },

    { key: 'status' },
  ]
  const perPage = ref(100)
  const totalPrizes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const dataMeta = computed(() => {
    const localItemsCount = refPrizesListTable.value ? refPrizesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPrizes.value,
    }
  })

  const refetchData = () => {
    refPrizesListTable.value.refresh()
  }

  watch([currentPage, perPage, debounceValue], () => {
    refetchData()
  })

  const resolveStatusText = status => {
    if (status === 'waiting') return 'Waiting'
    if (status === 'in_preview') return 'In preview'
    if (status === 'approved') return 'Approved'
    if (status === 'transferred') return 'transferred'
    // return 'primary'
  }

  const resolveStatusVariant = status => {
    if (status === 'waiting') return 'light-warning'
    if (status === 'in_preview') return 'light-warning'
    if (status === 'approved') return 'light-primary'
    if (status === 'transferred') return 'light-success'
    // return 'primary'
  }

  const resolvePercentText = percent => {
    if (!percent) return '100%'
    if (percent < 100) return `${percent}%`
    return '100%'
  }

  const resolvePercentVariant = percent => {
    if (percent === 25) return 'light-warning'
    if (percent === 50) return 'light-primary'
    return 'light-success'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalPrizes,
    dataMeta,
    perPageOptions,
    refPrizesListTable,
    refetchData,
    resolveStatusText,
    resolveStatusVariant,
    searchValue,
    debounceValue,
    timeout,
    resolvePercentText,
    resolvePercentVariant,
  }
}
