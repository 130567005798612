<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <b-row class="mb-2">
          <b-col
            cols="0"
            md="4"
          />
          <b-col
            cols="0"
            md="4"
          />

          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchValue"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>prizes</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >

            <b-button
              :variant="!isApprovedTop10Back ? '' : 'primary'"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="!isApprovedTop10Back"
              @click="showModal"
            >
              Approve Back Prizes
            </b-button>

          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPrizesListTable"
        :items="fetchPrizes"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loadingBackPrize"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchPrizes.length === 0 && !loadingBackPrize"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(id)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold id-column">
              {{ item.groupId ? '' : item.userId }}
            </div>
          </b-media>
        </template>
        <!-- Column: user name -->
        <template #cell(name)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{
                item.groupId ? '' : item.username
              }}
            </div>
          </b-media>
        </template>
        <!-- Column: betid -->
        <template #cell(betid)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.competitionUserId }}
            </div>
          </b-media>
        </template>
        <!-- Column: prize order -->
        <template #cell(prizeOrder)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center ml-2 font-weight-bold">
              {{ item.rank ? toCardinal(item.rank) : null }}
            </div>
          </b-media>
        </template>
        <!-- Column:Prize value -->
        <template #cell(prizeValue)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.prizeValue }}
            </div>
          </b-media>
        </template>
        <!-- Column: Percentage -->
        <template #cell(percent)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="resolvePercentVariant(item.percent)"
            >
              {{ resolvePercentText(item.percent) }}
            </b-badge>
          </b-media>
        </template>
        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="resolveStatusVariant(item.status)"
            >
              {{ resolveStatusText(item.status) }}
            </b-badge>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} prizes
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPrizes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      ref="modal"
      title="Approve Prize"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loadingTransferBack"
      @ok="handlePrizeTransferBack"
    >
      <b-card-text class="text-black">
        Do you want to Approve the Back Prize? You will not be able to undo it after confirmation
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingTransferBack" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import { UserAction, UserClosed } from '@core/utils/enum/user'
import { avatarText } from '@core/utils/filter'
import { formatEventDate, formatDateTimeAustralia, toCardinal } from '@/helpers/utils'
import {
  BCard,
  BCol,

  BMedia,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BButton,
  BBadge,
  BCardText,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'
import Spinner from '@core/components/Spinner'
import usePrizesList from './usePrizesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BCardText,
    BPagination,
    vSelect,
    BButton,
    BBadge,
    BFormInput,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    isRefresh: Boolean,
  },
  data() {
    return {
      UserClosed,
      action: {
        type: '',
        id: '',
      },
      modal: {
        title: '',
        content: '',
      },
      UserAction,
      formatDateTimeAustralia,
    }
  },

  computed: {
    ...mapState('races/listLeaderboard', ['listPrizes', 'loadingBackPrize', 'totalBackPrizes', 'isApprovedTop10Back']),
    ...mapState('races/prizeTransfer', ['loadingTransfer', 'loadingTransferBack', 'loadingTransferBack']),
    searchValue: {
      get() {
        return this.debounceValue
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debounceValue = val
        }, 500)
      },
    },
  },
  watch: {
    isRefresh() {
      this.refPrizesListTable.refresh()
    },
  },
  methods: {
    ...mapActions({
      getListBackPrize: 'races/listLeaderboard/getListBackPrize',
      setPrizeTransferBack: 'races/prizeTransfer/setPrizeTransferBack',
    }),
    async fetchPrizes() {
      const data = await this.getListBackPrize({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: this.$route.params.id,
        username: this.debounceValue,
      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching prizes list')
      this.totalPrizes = this.totalBackPrizes

      return data
    },

    showModal() {
      this.$refs.modal.show()
    },
    async handlePrizeTransferBack() {
      const res = await this.setPrizeTransferBack({ competitionId: this.$route.params.id, entryType: 1 })
      if (res) {
        setTimeout(() => this.refetchData(), 5000)
        this.showToastSuccess('Success', 'Please await around 5s for processing transfer back prizes')
      }
    },

  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const {
      tableColumns,
      perPage,
      currentPage,
      totalPrizes,
      dataMeta,
      perPageOptions,
      refPrizesListTable,
      refetchData,
      resolveStatusText,
      resolveStatusVariant,
      resolvePercentText,
      resolvePercentVariant,
      debounceValue,
      timeout,
    } = usePrizesList()

    return {
      getUserData,

      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      refetchData,

      avatarText,
      showToastError,
      showToastSuccess,
      isRoleSuperAdmin,
      formatEventDate,
      totalPrizes,
      refPrizesListTable,
      toCardinal,
      resolveStatusText,
      resolveStatusVariant,
      resolvePercentText,
      resolvePercentVariant,
      debounceValue,
      timeout,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.id-column {
  max-width: 150px;
  word-break: break-word;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
