import { ref, watch } from '@vue/composition-api'

export default function useEventsList() {
  // Use toast
  const refEventsCompTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'COMP RACE EVENT ID' },
    { key: 'name' },
    { key: 'firstRaceStartTime', label: 'FIRST RACE START TIME' },
    { key: 'lastRaceStartTime', label: 'LAST RACE START TIME' },
    { key: 'raceType', label: 'RACE TYPE' },
  ]

  const totalRaces = ref(0)
  const currentPage = ref(1)

  const refetchData = () => {
    refEventsCompTable.value.refresh()
  }

  watch([currentPage], () => {
    refetchData()
  })

  return {
    tableColumns,
    currentPage,
    totalRaces,
    refEventsCompTable,
    refetchData,
  }
}
