var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"0","md":"4"}}),_c('b-col',{attrs:{"cols":"0","md":"4"}}),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("prizes")])],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"8"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":!_vm.isApprovedTop10Back ? '' : 'primary',"disabled":!_vm.isApprovedTop10Back},on:{"click":_vm.showModal}},[_vm._v(" Approve Back Prizes ")])],1)],1)],1),_c('b-table',{ref:"refPrizesListTable",attrs:{"items":_vm.fetchPrizes,"fields":_vm.tableColumns,"responsive":"","show-empty":"","primary-key":"id"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.loadingBackPrize)?_c('div',{staticClass:"text-center my-2"},[_c('p',[_vm._v("Loading... Please wait")])]):_vm._e(),(_vm.fetchPrizes.length === 0 && !_vm.loadingBackPrize)?_c('div',{staticClass:"text-center my-2"},[_vm._v(" No matching records found ")]):_vm._e()]},proxy:true},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_vm._v(" "+_vm._s(item.groupId ? '' : item.userId)+" ")])])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.groupId ? '' : item.username)+" ")])])]}},{key:"cell(betid)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.competitionUserId)+" ")])])]}},{key:"cell(prizeOrder)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center ml-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.rank ? _vm.toCardinal(item.rank) : null)+" ")])])]}},{key:"cell(prizeValue)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.prizeValue)+" ")])])]}},{key:"cell(percent)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('b-badge',{attrs:{"pill":"","variant":_vm.resolvePercentVariant(item.percent)}},[_vm._v(" "+_vm._s(_vm.resolvePercentText(item.percent))+" ")])],1)]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('b-badge',{attrs:{"pill":"","variant":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.resolveStatusText(item.status))+" ")])],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" prizes ")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalPrizes,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('b-modal',{ref:"modal",attrs:{"title":"Approve Prize","centered":"","cancel-variant":"outline-secondary","ok-disabled":_vm.loadingTransferBack},on:{"ok":_vm.handlePrizeTransferBack},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.loadingTransferBack)?_c('Spinner'):_c('span',[_vm._v("Submit")])]},proxy:true}])},[_c('b-card-text',{staticClass:"text-black"},[_vm._v(" Do you want to Approve the Back Prize? You will not be able to undo it after confirmation ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }