<template>
  <section>
    <b-card>
      <div class="d-flex align-items-center my-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <h4 class="mb-0 ml-50">
          Competition Detail Settings:
        </h4>
      </div>

      <!-- JOIN COMP TYPE -->
      <b-row class=" mt-1 d-flex align-items-start">
        <b-col
          cols="3"
          class="font-weight-bold"
          style="margin-top: 10px;"
        >
          <p>OPTION JOIN COMP</p>
        </b-col>
        <b-col
          cols="9"
          class="d-flex align-items-center"
        >
          <b-form-radio-group
            id="join-comp-type"
            v-model="selectedJoinComp"
            :options="optionsJoinComp"
            name="join-comp-type"
          />
          <b-button
            variant="primary"
            class="btn-set ml-2 "
            @click="handleChangeBetslipMode"
          >
            <Spinner v-if="loadingChangeBetslipMode" />
            <template v-else>
              Apply
            </template>
          </b-button>
        </b-col>
      </b-row>

      <!-- PUBLIC FLEXI AMOUNT -->
      <b-row class="mt-1 d-flex align-items-start">
        <b-col
          cols="3"
          class="font-weight-bold"
          style="margin-top: 10px;"
        >
          <p>PUBLIC FLEXI AMOUNT</p>
        </b-col>
        <b-col
          cols="9"
          class="d-flex align-items-center"
        >
          <b-form-radio-group
            id="show-flexi-amount"
            v-model="selectedShowFlexiAmount"
            :options="optionsShowFlexiAmount"
            name="show-flexi-amount"
          />
          <b-button
            variant="primary"
            class="btn-set ml-2 "
            @click="handleCheckShowFlexi"
          >
            <Spinner v-if="loadingShowFlexiAmount" />
            <template v-else>
              Apply
            </template>
          </b-button>
        </b-col>
      </b-row>

      <!-- MAX STAKE -->
      <b-row>
        <b-col
          cols="3"
          style="margin-top: 10px;"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            MAXIMUM STAKE
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="max-stake"
            :value="formatCurrencyBuiltInFunc(dataDetail.competitionAdvance && dataDetail.competitionAdvance.maximumBetSlipStake ? dataDetail.competitionAdvance.maximumBetSlipStake : 0)"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="maximumStake"
                :formatter="formatNumberPrize"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingMaximumStake"
              class="btn-set ml-2 mt-2"
              @click="handleApplyMaximumStake"
            >
              <Spinner v-if="loadingMaximumStake" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <!-- QP BTN IN PYO -->
      <b-row class="mt-1 d-flex align-items-start">
        <b-col
          cols="3"
          class="font-weight-bold"
          style="margin-top: 10px;"
        >
          <p>QP BUTTON IN PYO</p>
        </b-col>
        <b-col
          cols="9"
          class="d-flex align-items-center"
        >
          <b-form-radio-group
            id="toggle-qp-legs"
            v-model="selectedShowQPLegs"
            :options="optionsShowQPLegsBtn"
            name="toggle-qp-legs"
          />
          <b-button
            variant="primary"
            class="btn-set ml-2 "
            @click="handleCheckQPLegsBtn"
          >
            <Spinner v-if="loadingShowFlexiAmount" />
            <template v-else>
              Apply
            </template>
          </b-button>
        </b-col>
      </b-row>

      <!-- BET AGAINST YOURSELF -->
      <b-row class="mt-1 d-flex align-items-start">
        <b-col
          cols="3"
          class="font-weight-bold"
          style="margin-top: 10px;"
        >
          <p>BET AGAINST YOURSELF</p>
        </b-col>
        <b-col
          cols="9"
          class="d-flex align-items-center"
        >
          <b-form-radio-group
            id="bet-against-yourself"
            v-model="selectedBetAgainstYourself"
            :options="optionsBetAgainstYourself"
            name="bet-against-yourself"
          />
          <b-button
            variant="primary"
            class="btn-set ml-2 "
            :disabled="checkIsChangeBetAgainst"
            @click="handleCheckShowModal"
          >
            Apply
          </b-button>
        </b-col>
      </b-row>

      <!-- MAX MARKET -->
      <b-row v-if="dataDetail.competitionAdvance && dataDetail.competitionAdvance.betYourselfSetting && selectedBetAgainstYourself">
        <b-col
          cols="3"
          style="margin-top: 10px;"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            MAX MARKET
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="max-stake"
            :value="currentMaxMarket"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="newMaxMarket"
                :formatter="formatNumber"
              />
            </b-col>

            <b-button
              variant="primary"
              class="btn-set ml-2 mt-2"
              style="width: 87px;"
              @click="handleApplyNewMaxMarket"
            >
              <Spinner v-if="loadingNewMaxMarket" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <!-- ADD NEW LEAGUE -->

      <b-modal
        ref="bet"
        title="Bet Against Yourself"
        centered
        hide-footer
        cancel-variant="outline-secondary"
        size="md"
        @hide="hideModal"
      >
        <section style="color: black">
          <div v-if="selectedBetAgainstYourself">

            <!-- maxMarket -->
            <b-row class="mt-2">
              <b-col
                cols="5"
                class="d-flex align-items-center"
              >
                <div class="font-weight-bold">
                  Maximum Market
                </div>
              </b-col>
              <b-col
                cols="6"
                class="d-flex"
                style="flex-direction: column"
              >
                <b-form-input
                  v-model="maxMarket"
                  placeholder="eg. 2"
                  :formatter="formatNumber"
                />

              </b-col>
            </b-row>
            <b-row>
              <b-col cols="5" />
              <b-col
                cols="6"
                class="d-flex"
                style="flex-direction: column"
              >
                <div v-if="formatListMarket()">
                  {{ formatListMarket() }}
                </div></b-col>
            </b-row>
            <small
              v-if="errMaxMarket"
              class="text-danger"
            >{{ errMaxMarket }}</small>

          </div>
          <div v-else>
            <p style="color: black; font-size: 16px; font-weight: bold;">
              Do you want to turn off bet against yourself for this comp?
            </p>
          </div>

          <b-row>
            <b-col class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="loadingBAY"
                @click="handleSaveChangeBetAgainstYourself"
              >
                <Spinner v-if="loadingBAY" />
                <template v-else>
                  Save
                </template>
              </b-button>
            </b-col>
          </b-row>
        </section>
      </b-modal>

    </b-card>

  </section>
</template>

<script>
import {
  BCol, BRow, BCard,
  BFormRadioGroup,
  BButton,
  BFormInput,
  VBModal,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import {
  formatDateQuery, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import useToastHandler from '@/services/toastHandler'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormRadioGroup,
    BButton,
    Spinner,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      optionsJoinComp: [
        { text: 'Both', value: null },
        { text: 'Only PYO', value: 'pyo' },
        { text: 'Only QP', value: 'quick-pick' },
      ],
      selectedJoinComp: null,

      optionsShowFlexiAmount: [
        { text: 'Hide', value: false },
        { text: 'Show', value: true },
      ],
      selectedShowFlexiAmount: false,

      maximumStake: null,

      optionsShowQPLegsBtn: [
        { text: 'Show', value: false },
        { text: 'Hide', value: true },
      ],
      selectedShowQPLegs: false,

      optionsBetAgainstYourself: [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ],
      selectedBetAgainstYourself: false,
      maxMarket: null,
      errMaxMarket: '',
      maxEstReturnEachMarket: null,
      errEstReturn: '',
      loadingNewMaxMarket: false,
      currentMaxMarket: null,
      newMaxMarket: null,
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('races/setting', ['loadingShowFlexiAmount', 'loadingChangeBetslipMode', 'loadingMaximumStake', 'loadingBAY']),
    checkIsChangeBetAgainst() {
      if (!this.dataDetail.competitionAdvance) return !this.dataDetail.competitionAdvance && !this.selectedBetAgainstYourself
      const check = !!this.dataDetail.competitionAdvance.betYourselfSetting
      return (check && this.selectedBetAgainstYourself) || (!check && !this.selectedBetAgainstYourself)
    },

  },
  watch: {
    dataDetail(val) {
      if (val.competitionAdvance) {
        const BAY = val.competitionAdvance.betYourselfSetting
        if (BAY) {
          this.selectedBetAgainstYourself = true
          this.currentMaxMarket = BAY.maxMarket
        }
      }
    },
    maxMarket(val) {
      if (val && this.errMaxMarket) this.errMaxMarket = ''
    },
  },

  async mounted() {
    if (this.dataDetail) {
      if (this.dataDetail.competitionAdvance) {
        this.selectedShowFlexiAmount = this.dataDetail.competitionAdvance.isFlexiLeaderboardVisible
        this.selectedShowQPLegs = this.dataDetail.competitionAdvance.isDisabledQuickPickLeg
        const mode = this.dataDetail.competitionAdvance.betSlipMode
        if (mode) { this.selectedJoinComp = mode } else {
          this.selectedJoinComp = null
        }
      }
      if (!this.dataDetail.competitionAdvance) return
      const BAY = this.dataDetail.competitionAdvance.betYourselfSetting
      if (BAY) {
        this.selectedBetAgainstYourself = true
        this.currentMaxMarket = BAY.maxMarket
      }
    }
  },
  methods: {
    ...mapActions({
      handleShowFlexiAmount: 'races/setting/handleShowFlexiAmount',
      getCompDetail: 'races/compDetail/getCompDetail',
      checkBetSlipMode: 'races/setting/checkBetSlipMode',
      checkMaximumStake: 'races/setting/checkMaximumStake',
      handleShowQPLegsBtn: 'races/setting/handleShowQPLegsBtn',
      handleChangeSettingBAY: 'races/setting/handleChangeSettingBAY',
    }),
    async  handleCheckShowFlexi() {
      const res = await this.handleShowFlexiAmount({
        competitonId: this.$router.currentRoute.params.id,
        isFlexiLeaderboardVisible: this.selectedShowFlexiAmount,
      })
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Change option show flexi successfully!')
      } else if (this.dataDetail.competitionAdvance) this.selectedShowFlexiAmount = this.dataDetail.competitionAdvance.isFlexiLeaderboardVisible
    },
    async  handleChangeBetslipMode() {
      const res = await this.checkBetSlipMode({
        competitonId: this.$router.currentRoute.params.id,
        betSlipMode: this.selectedJoinComp,
      })
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Change option buy comp successfully!')
      } else {
        const mode = this.dataDetail.competitionAdvance.betSlipMode
        if (mode) { this.selectedJoinComp = mode } else {
          this.selectedJoinComp = null
        }
      }
    },
    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleApplyMaximumStake() {
      if (!this.maximumStake) return this.showToastError('Error', 'Set maximum stake failed')
      const res = await this.checkMaximumStake({
        competitonId: this.$router.currentRoute.params.id,
        maximumBetSlipStake: Number(this.maximumStake),
      })
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Set maximum stake successfully!')
        this.maximumStake = null
      }
    },
    async  handleCheckQPLegsBtn() {
      const res = await this.handleShowQPLegsBtn({
        competitonId: this.$router.currentRoute.params.id,
        isDisabledQuickPickLeg: this.selectedShowQPLegs,
      })
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Change option show QP legs successfully!')
      } else {
        this.selectedShowQPLegs = this.dataDetail.competitionAdvance.isDisabledQuickPickLeg
      }
    },
    hideModal() {
      this.$refs.bet.hide()
      this.maxMarket = null
      this.errMaxMarket = ''
    },
    async handleSaveChangeBetAgainstYourself(event) {
      event.preventDefault()
      if (!this.selectedBetAgainstYourself) {
        const res = await this.handleChangeSettingBAY({
          competitonId: this.$route.params.id,
          betYourselfSetting: null,
        })
        if (res) {
          this.hideModal()
          this.selectedBetAgainstYourself = false
          this.showToastSuccess('Success', 'Change option BET AGAINST YOURSELF successfully!')
        } else {
          this.selectedShowQPLegs = !this.selectedShowQPLegs
        }
      }
      if (!this.maxMarket) this.errMaxMarket = 'Max market is required'
      if (this.errMaxMarket) return
      const res = await this.handleChangeSettingBAY({
        competitonId: this.$route.params.id,
        betYourselfSetting: {
          maxMarket: Number(this.maxMarket),
        },
      })
      if (res) {
        this.hideModal()
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Change option BET AGAINST YOURSELF successfully!')
      } else {
        this.selectedShowQPLegs = !!this.dataDetail.competitionAdvance.betYourselfSetting
      }
    },
    handleCheckShowModal() {
      this.$refs.bet.show()
    },
    formatNumber(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    async  handleApplyNewMaxMarket() {
      if (!this.newMaxMarket) return
      this.loadingNewMaxMarket = true
      const res = await this.handleChangeSettingBAY({
        competitonId: this.$route.params.id,
        betYourselfSetting: {
          maxMarket: Number(this.newMaxMarket),

        },
      })
      if (res) {
        this.currentMaxMarket = this.newMaxMarket
        this.showToastSuccess('Success', 'Change max market successfully!')
      }
      this.loadingNewMaxMarket = false
      this.newMaxMarket = null
    },
    formatListMarket() {
      if (!this.maxMarket) return null
      const list = Array.from({ length: this.maxMarket }, (_, i) => i + 1)
      const formatList = list.map(i => {
        if (i === 1) return 'Any 0'
        return `Any ${i}`
      })
      return formatList.join(', ')
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      showToastError,
      formatDateQuery,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .btn-set {
    height: fit-content;
  }
  .add-btn {
    padding: 2px 4px;
  }

  .remove-btn {
    padding: 0px 4px 2px 4px;
    margin-left: 1px;
  }

  .delete-btn {
    color: red;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .v-select .vs__dropdown-toggle,
  .v-select .vs__search {
    cursor: pointer;
  }
  @import '@core/scss/vue/libs/vue-select.scss';

  .ml-14 {
    margin-left: 14px;
  }
  </style>
