<template>
  <div class="mt-2">
    <!-- CONTENT POPUP -->
    <b-card style="border: 1px solid black;">
      <div class="d-flex align-items-center justify-content-between">
        <h4
          class="mb-1 mr-1"
          style="font-weight: 900;"
        >
          Content flash banner for free betslip:
        </h4>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 ml-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleChangeContentMess"
        >
          <template> Change Content </template>
        </b-button>
      </div>
      <div class="d-flex align-items-center">
        <p
          class="mb-0"
          style="font-size: 16px; font-weight: bold; margin-right: 8px;"
        >
          Image:
        </p>
        <b-img
          v-if="contentPopup && contentPopup.png && contentPopup.png.url"
          style="width: 30px; height: 30px; cursor: pointer;"
          :src="contentPopup.png.url"
          alt="logo"
        />
      </div>

      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Title: </span>{{ contentPopup && contentPopup.title && contentPopup.title.value ? contentPopup.title.value : "" }}
      </p>
      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Top text: </span>{{ contentPopup && contentPopup.topText && contentPopup.topText.value ? contentPopup.topText.value : "" }}
      </p>
      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Middle text: </span>{{ contentPopup && contentPopup.middleText && contentPopup.middleText.value ? contentPopup.middleText.value : "" }}
      </p>
      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Bottom text: </span>{{ contentPopup && contentPopup.bottomText && contentPopup.bottomText.value ? contentPopup.bottomText.value : "" }}
      </p>

      <!-- </div> -->
    </b-card>
    <b-card>
      <div class="d-flex align-items-center">
        <h4>
          Free Betslip:
        </h4>
        <div v-if="total === 0">
          <b-button
            v-if="!dataComp.flexiValues"
            variant="primary"
            class="mb-1 mb-sm-0 ml-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleAddUser"
          >
            <template> Create Free Betslip </template>
          </b-button>

          <div v-else>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 ml-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              style="margin-top: 5px;"
              @click="handleAddUser"
            >
              <template> ${{ dataComp.price }} Create Free Betslip </template>
            </b-button>
            <b-button
              v-for="(item, ind) in dataComp.flexiValues"
              :key="ind"
              variant="primary"
              class="mb-1 mb-sm-0 ml-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              style="margin-top: 5px;"
              @click="handleAddUser(item)"
            >
              <template> ${{ item*dataComp.price/100 }} Create Free Betslip </template>
            </b-button>

          </div>

        </div>

      </div>
      <div style="display: flex; justify-content: flex-end;">
        <b-button
          v-if="total > 0 && !dataComp.flexiValues"
          variant="danger"
          class="mb-1 mb-sm-0 ml-2 mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"

          @click="handleApproveFreeBetslip"
        >
          <Spinner
            v-if="loadingApprove"
            style="width: 150px;"
          />
          <span v-else>Approve Free Betslip</span>
        </b-button>
        <div v-if="total > 0 && dataComp.flexiValues">
          <b-button
            variant="danger"
            class="mb-1 mb-sm-0 ml-2 mt-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleApproveFreeBetslip"
          >
            <template> ${{ dataComp.price }} Approve Free Betslip </template>
          </b-button>
          <b-button
            v-for="(item, ind) in dataComp.flexiValues"

            :key="ind"
            variant="danger"
            class="mb-1 mb-sm-0 ml-2 mt-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleApproveFreeBetslip(item)"
          >
            <template> ${{ item*dataComp.price/100 }} Approve Free Betslip </template>
          </b-button>

        </div>
      </div>
      <p class="text-danger mt-2">
        <span style="font-weight: bold;">*Note: </span>  please waiting for all free betslip added
      </p>
    </b-card>

    <!-- MODAL ADD FREE BETSLIP -->
    <b-modal
      ref="free-betslip"
      hide-footer
      title="Add Free Betslip"
      centered
      cancel-variant="outline-secondary"
      size="md"
      @hide="hideModal"
    >

      <!-- AMOUNT BETSLIP -->
      <b-row class="mt-1 mb-1">
        <b-col
          cols="5"
          class="d-flex"
          style="color: black; align-items: center;"
        >
          <div class="font-weight-bold">
            Amount Free Betslip:
          </div>
        </b-col>
        <b-col
          cols="7"
          class="d-flex"
          style="flex-direction: column"
        >
          <b-form-input
            v-model="amountFreeBetslip"
            placeholder="Enter number betslip..."
            type="number"
            :no-wheel="true"
          />
        </b-col>
      </b-row>

      <div style="display: flex; gap: 4px; align-items: center; justify-content: space-between; margin-bottom: 10px;">
        <b-button
          v-if="csvData.length > 0"
          variant="primary"
          class=" add-btn"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          style=" margin-right: 20px; font-size: 12px; padding: 4px;"
          @click="handleShowReviewList"
        >
          <template> Review list </template>
        </b-button>
        <input
          ref="fileInput"
          type="file"
          accept=".csv"
          hidden
          @change="handleFileUpload"
        >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-success"
          class="btn-export"
          @click="onClickInput"
        >
          <span>Upload UserNames (CSV)</span>
        </b-button>

      </div>
      <b-row class="text-modal">
        <b-form-input
          v-model="searchQuery"
          type="text"
          placeholder="Search..."
        />
        <b-form-group
          class="container"
          style="margin-top: 10px;"
        >
          <b-form-checkbox
            v-for="item in users"
            :key="item.userId"
            v-model="selectedUsers"
            name="event-filter"
            :value="item"
            class="mb-1 ml-2"
            checked="true"
          >
            {{ item.username }}
          </b-form-checkbox>
        </b-form-group>

      </b-row>

      <div style="display: flex; align-items: center; gap: 10px; justify-content: center;">
        <feather-icon
          v-if="Number(page) > 1"
          icon="ChevronLeftIcon"
          size="18"
          style="color: black; cursor: pointer;"
          @click="handleMinusPage"
        />
        <feather-icon
          v-if="Number(page) === 1"
          icon="ChevronLeftIcon"
          size="18"
          style="color: #D9D9D9;"
        />
        <b-form-input
          id="page"
          v-model="page"
          type="number"
          style="padding: 6px; padding-left: 10px; padding-right: 10px; border-radius: 20px; font-weight: bold; background-color: #2B65A5; width: 50px; color: white; text-align: center;"
          :formatter="formatter"
        />
        <feather-icon
          v-if="Number(page) < totalPage"
          icon="ChevronRightIcon"
          size="18"
          style="color: black; cursor: pointer;"
          @click="handlePlusPage"
        />
        <feather-icon
          v-if="Number(page) === totalPage"
          icon="ChevronRightIcon"
          size="18"
          style="color: #D9D9D9;"
        />
      </div>
      <p
        v-if="errorAmountFreeBetslip"
        class="text-danger"
        style="margin-top: 6px; margin-bottom: 2px;"
      >{{ errorAmountFreeBetslip }}</p>
      <b-row>
        <b-col class="d-flex justify-content-end ">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleCreateFree"
          >
            <Spinner v-if="isLoadingAddFile" />
            <span v-else>Add</span>
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
    <!-- Modal review -->
    <b-modal
      ref="list-review"
      hide-footer
      title="Review list"
      centered
      cancel-variant="outline-secondary"
      size="md"
    >
      <div style="display: flex; gap: 4px; align-items: center; justify-content: space-between;">
        <div
          style="font-size: 16px; font-weight: bold; color: black; margin-bottom: 4px;"
        >
          {{ csvData.length }} users uploaded
        </div>

      </div>
      <b-row
        class="mb-2"
        style="max-height: 220px; overflow-y: scroll;"
      >

        <b-col
          v-for="(i, ind) in dataPerPage()"
          :key="ind"
          md="6"
          style="display: flex; align-items: center; gap: 10px; row-gap: 16px;"
        >
          <div style="min-width: 150px;">

            <b-badge

              pill
              variant="light-success"
            >
              {{ i.username }}
            </b-badge>
          </div>

        </b-col>
      </b-row>

      <div
        v-if="csvData.length > 20"
        class="mx-2"
      >
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPageReview"
              :total-rows="csvData.length"
              :per-page="perPageReview"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-modal>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>users</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refParticipantListTable"
        :items="fetchParticipant"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchParticipant.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- Column: review -->
        <template #cell(review)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ item.amount - item.temporaryGenerated }}
            </div>
          </div>
        </template>
        <!-- Column: temporaryGenerated -->
        <template #cell(temporaryGenerated)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ item.temporaryGenerated - item.realGenerated }}
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalParticipant"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- MODAL CHANGE CONTENT POPUP -->
    <b-modal
      ref="content"
      hide-footer
      title="Change Text Content"
      centered
      cancel-variant="outline-secondary"
      size="xl"
      @hide="hideModalContent"
    >
      <div style="color: black;">

        <!-- TITLE -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Title:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="titleContent"
              type="text"
              placeholder="Add title..."
            />
          </b-col>
        </b-row>

        <!-- Top text -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Top text:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <!-- <b-form-input
              v-model="topTextContent"
              type="text"
              placeholder="Add top text..."
            /> -->
            <div style="height: 150px; overflow-y: hidden; ">
              <vue-editor
                v-model="topTextContent"
                :editor-options="editorOptions"
                show-preview="true"
                style="height: 100px;"
              />

            </div>
          </b-col>
        </b-row>

        <!-- middle text -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Middle text:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <!-- <b-form-input
              v-model="middleTextContent"
              type="text"
              placeholder="Add top text..."
            /> -->
            <div style="height: 150px; overflow-y: hidden;">
              <vue-editor
                v-model="middleTextContent"
                :editor-options="editorOptions"
                style="height: 100px;"
              />

            </div>
          </b-col>
        </b-row>
        <!-- bottom text -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Bottom text:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="bottomTextContent"
              type="text"
              placeholder="Add top text..."
            />
          </b-col>
        </b-row>
        <!-- image -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Image:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <div
              v-if="!imageContent"
              style="cursor: pointer;"
              @click="$refs.refInputEl.$el.click()"
            >
              <feather-icon
                icon="UploadIcon"
                size="16"
                style="margin-left: 8px; "
              /> Upload

            </div>
            <b-img
              v-if="imageContent"
              style="width: 30px; height: 30px; cursor: pointer;"
              :src="imageContent"
              alt="logo"
              @click="$refs.refInputEl.$el.click()"
            />

            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png, .gif, .heic, .jpeg"
              :hidden="true"
              plain
              style="cursor: pointer;"
              @change="inputImageRenderer"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end ">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveChange"
            >
              <Spinner v-if="loadingGetData" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

    </b-modal>

    <!-- MODAL CONFIRM -->
    <b-modal
      ref="modal"
      title="Confirm Approve Free Betslip"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitApprove"
    >
      <b-card-text class="text-title">
        Do you want to approve this list {{ typeof activeFlexi === 'number' ? 'with flexi' : 'with full betslip' }}  <span v-if="typeof activeFlexi === 'number'">{{ activeFlexi }}</span>?
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingApprove" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCol, BPagination, BRow, BTable, BButton, VBModal, BFormInput, BFormGroup, BFormCheckbox, BBadge, BImg, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
import { parse } from 'csv-parse'
import { objectProp } from '@/helpers/props'
import { VueEditor } from 'vue2-editor'
import useFreeBetslipList from './useFreeBetslipList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BTable,
    BPagination,
    BButton,
    Spinner,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BBadge,
    BImg,
    BFormFile,
    VueEditor,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    dataComp: objectProp(),
  },
  data() {
    return {
      searchText: '',
      totalPage: 1,
      currentPageUser: 1,
      selectedUsers: [],
      users: [],
      totalUsers: null,
      timeout: null,
      csvData: [],
      formatCsvData: [],
      page: 1,

      amountFreeBetslip: 1,
      errorAmountFreeBetslip: '',

      currentPageReview: 1,
      perPageReview: 20,

      isLoadingAddFile: false,
      contentPopup: null,

      titleContent: '',
      topTextContent: '',
      middleTextContent: '',
      bottomTextContent: '',
      imageContent: '',

      activeFlexi: null,

      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ color: [] }],
          ],
        },
        placeholder: 'Add text...',
        theme: 'snow', // or 'bubble'
        formats: [
          'bold', 'italic', 'underline', 'color', 'background',
        ],
        // Set default text color to black (#000000)
        styles: {
          '.ql-editor': {
            color: '#ffffff',
          },
        },
      },

    }
  },
  computed: {
    ...mapState({
      list: s => s.races.list,
    }),
    ...mapState('races/freeBetslip', ['loading', 'total', 'loadingApprove', 'loadingCreate', 'loadingClear']),
    ...mapState('setting/setting', ['loadingGetData']),
    searchQuery: {
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.page = 1
          this.searchText = val
          this.fetchUsers()
        }, 500)
      },
    },
  },
  watch: {
    page() {
      this.fetchUsers()
    },
    amountFreeBetslip(val) {
      if (Number(val) > 0 && this.selectedUsers.length > 0) this.errorAmountFreeBetslip = ''
    },
    selectedUsers(val) {
      if (Number(this.amountFreeBetslip) > 0 && val.length > 0) this.errorAmountFreeBetslip = ''
    },
    csvData(val) {
      if (Number(this.amountFreeBetslip) > 0 && val.length > 0) this.errorAmountFreeBetslip = ''
    },
    currentPageReview() {
      this.dataPerPage()
    },
  },
  async created() {
    this.users = await this.fetchUsers()
    this.contentPopup = await this.getSetting('flash-banner-for-free-quick-pick')
    this.hideModalContent()
  },
  methods: {
    ...mapActions({
      getListFreeBetslipByComp: 'races/freeBetslip/getListFreeBetslipByComp',
      approveFreeBetslip: 'races/freeBetslip/approveFreeBetslip',
      asyncUsers: 'races/list/asyncUsers',
      createFreeBetslip: 'races/freeBetslip/createFreeBetslip',
      getSetting: 'setting/setting/getSetting',
      uploadFlag: 'sportComp/createComp/uploadFlag',
      setNewSettingFreeQuickPick: 'setting/setting/setNewSettingFreeQuickPick',
      clearListFreeBetslip: 'races/freeBetslip/clearListFreeBetslip',
    }),
    async fetchParticipant() {
      const data = await this.getListFreeBetslipByComp({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: Number(this.$route.params.id),
        status: 'review',
      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalParticipant = this.total

      return data
    },
    async fetchUsers() {
      if (!this.page) this.page = 1
      const data = await this.asyncUsers({
        search: this.searchText,
        limit: 10,
        page: this.page < 1 ? 1 : this.page,
      })

      this.totalUsers = this.list.totalPage
      this.totalPage = Math.ceil(this.list.totalPage / 10)
      this.users = data
      return data
    },
    dataPerPage() {
      const startIndex = (this.currentPageReview - 1) * this.perPageReview
      const endIndex = startIndex + this.perPageReview
      const itemsToShow = this.csvData.slice(startIndex, endIndex)
      return itemsToShow
    },
    handleApproveFreeBetslip(item) {
      this.activeFlexi = item
      this.$refs.modal.show()
      // const res = await this.approveFreeBetslip({ competitionId: Number(this.$route.params.id), flexi: item })
      // if (res) {
      //   setTimeout(() => {
      //     this.refetchData()
      //   }, 3000)
      //   this.showToastSuccess('Success', 'Approve free betslip for this comp successfully!')
      // }
      // this.activeFlexi = null
    },
    async handleSubmitApprove() {
      const res = await this.approveFreeBetslip({ competitionId: Number(this.$route.params.id), ...(typeof this.activeFlexi === 'number' && { flexi: this.activeFlexi }) })
      if (res) {
        setTimeout(() => {
          this.refetchData()
        }, 3000)
        this.showToastSuccess('Success', 'Approve free betslip for this comp successfully!')
      }
      this.activeFlexi = null
    },
    async  handlePlusPage() {
      if (this.page < this.totalPage) {
        this.page++
      }
    },
    async  handleMinusPage() {
      if (this.page > 1) {
        this.page--
      }
    },
    handleAddUser(item) {
      if (typeof item === 'number') {
        this.activeFlexi = item
      }
      this.fetchUsers()
      this.$refs['free-betslip'].show()
    },
    async handleCreateFree() {
      if (!this.amountFreeBetslip || (!this.selectedUsers.length && !this.csvData.length)) return this.errorAmountFreeBetslip = 'Please add users and amount free betslip before confirm'
      this.isLoadingAddFile = true
      // HAVE USER LIST
      if (this.csvData.length > 0) {
        if (this.formatCsvData.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.formatCsvData) {
            const list = item.map(x => x.userId)
            // eslint-disable-next-line no-await-in-loop
            const result = await this.createFreeBetslip({
              competitionId: Number(this.$route.params.id),
              amount: Number(this.amountFreeBetslip),
              userIds: list,
            })
            if (!result) return this.isLoadingAddFile = false
          }
          this.showToastSuccess('Success', 'Create free betslip successfully')
          this.refetchData()
          this.csvData = []
          this.formatCsvData = []
          this.isLoadingAddFile = false
          this.$refs['free-betslip'].hide()
          this.refetchData()
          return
        }
        const arr = this.csvData.map(i => i.userId)
        const result = await this.createFreeBetslip({
          competitionId: Number(this.$route.params.id),
          amount: Number(this.amountFreeBetslip),
          userIds: arr,
        })
        if (!result) return this.isLoadingAddFile = false
        this.csvData = []
        this.formatCsvData = []
        this.isLoadingAddFile = false
        this.$refs['free-betslip'].hide()
        this.refetchData()
        return this.showToastSuccess('Success', 'Create free betslip successfully')
      }

      const listUsers = this.selectedUsers.map(i => i.userId)
      const result = await this.createFreeBetslip({
        competitionId: Number(this.$route.params.id),
        amount: Number(this.amountFreeBetslip),
        ...(listUsers.length > 0 && { userIds: listUsers }),
      })
      if (!result) return this.isLoadingAddFile = false
      this.selectedUsers = []
      this.csvData = []
      this.formatCsvData = []
      this.isLoadingAddFile = false
      this.refetchData()
      this.$refs['free-betslip'].hide()
      this.showToastSuccess('Success', 'Create free betslip successfully')
    },
    formatter(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }

        if (Number(value) > this.totalPage) return this.totalPage
        if (Number(value) < 1) return '1'
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    onClickInput() {
      this.selectedUsers = []
      const fileInput = this.$refs.fileInput
      fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.parseCSV(reader.result)
      }
      reader.readAsText(file)
    },
    splitArray(array) {
      const result = []
      const length = array.length
      for (let i = 0; i < length; i += 200) {
        result.push(array.slice(i, i + 200))
      }
      return result
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const newList = data.map(i => ({
          username: i[1],
          userId: i[0],
        }))
        this.csvData = newList

        if (newList.length > 200) {
          const splitArrays = this.splitArray(newList)
          this.formatCsvData = splitArrays
        } else {
          this.formatCsvData = []
        }
      })
    },
    handleShowReviewList() {
      this.$refs['list-review'].show()
    },
    hideModal() {
      this.csvData = []
      this.selectedUsers = []
      this.amountFreeBetslip = 1
      this.searchText = ''
    },
    handleChangeContentMess() {
      this.$refs.content.show()
    },
    hideModalContent() {
      this.titleContent = this.contentPopup.title.value
      this.topTextContent = this.contentPopup.topText.value
      this.middleTextContent = this.contentPopup.middleText.value
      this.bottomTextContent = this.contentPopup.bottomText.value
      this.imageContent = this.contentPopup.png.url
    },
    async inputImageRenderer(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        formData.append('image', file)
        const res = await this.uploadFlag(formData)
        if (res) {
          this.imageContent = res
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    async handleSaveChange() {
      const res = await this.setNewSettingFreeQuickPick({
        value: {
          png: {
            url: this.imageContent,
          },
          title: {
            value: this.titleContent,
          },
          topText: {
            value: this.topTextContent,
          },
          middleText: {
            value: this.middleTextContent,
          },
          bottomText: {
            value: this.bottomTextContent,
          },
        },
      })
      if (res) {
        this.$refs.content.hide()
        this.showToastSuccess('Success', 'Change content popup successfully')
        this.contentPopup = await this.getSetting('flash-banner-for-free-quick-pick')
        this.hideModalContent()
      }
    },
    async handleClearList() {
      const res = await this.clearListFreeBetslip({ competitionId: Number(this.$route.params.id) })
      if (res) {
        this.refetchData()
        this.showToastSuccess('Success', 'Clear list free betslip for this comp successfully!')
      }
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const {
      tableColumns,
      perPage,
      currentPage,
      totalParticipant,
      dataMeta,
      perPageOptions,
      refParticipantListTable,
      refetchData,
    } = useFreeBetslipList()
    return {
      formatDate,
      showToastError,
      showToastSuccess,

      tableColumns,
      perPage,
      currentPage,
      totalParticipant,
      dataMeta,
      perPageOptions,
      refParticipantListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.active-title {
  color: white;
}

.text-title {
  color: #000;
}
.w-full{
  width: 100%;
  display: block;
}
</style>
