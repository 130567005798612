import { computed, ref, watch } from '@vue/composition-api'

export default function useFreeBetslipList() {
  // Use toast
  const refParticipantListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'userId' },
    { key: 'username' },
    { key: 'review', label: 'Total review free betslip' },
    { key: 'temporaryGenerated', label: 'Total Temporary generated' },
    { key: 'realGenerated', label: 'Total Real Generated' },
  ]
  const perPage = ref(10)
  const totalParticipant = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const dataMeta = computed(() => {
    const localItemsCount = refParticipantListTable.value ? refParticipantListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalParticipant.value,
    }
  })

  const refetchData = () => {
    refParticipantListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalParticipant,
    dataMeta,
    perPageOptions,
    refParticipantListTable,
    refetchData,

  }
}
