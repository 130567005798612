<template>
  <div class="mt-2">
    <b-button
      v-b-modal.user-list
      variant="primary"
      class="mb-2"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      style="border-radius: 18px;"
      :disabled="!dataDetail.competitionAdvance || !dataDetail.competitionAdvance.code"
    >
      <span> Add Users</span>
    </b-button>

    <b-button
      variant="danger"
      class="mb-2 ml-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      style="border-radius: 18px;"
      :disabled="!dataDetail.competitionAdvance || !dataDetail.competitionAdvance.code"
      @click="handleClearAll"
    >
      <span> Clear All</span>
    </b-button>
    <download-csv
      v-if="totalParticipant > 0"
      class="ml-1"
      :data="exportData"
      name="download.csv"
      style="display: inline-block;"
    >

      <b-button
        variant="primary"
        style="border-radius: 18px;"
        class="btn-change-status mb-2"
        @click="() => {}"
      >
        Export CSV
      </b-button>
    </download-csv>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>users</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refParticipantListTable"
        :items="fetchParticipant"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loadingGetUser"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchParticipant.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- Column: action -->
        <template #cell(action)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div
              class="d-flex align-items-center font-weight-extrabold"
              style="cursor: pointer;"
              @click="handleShowModalDeleteGroup(item)"
            >
              <feather-icon icon="TrashIcon" />

            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalParticipant"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- MODAL DELETE -->
    <b-modal
      ref="modal"
      title="Delete User"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitApproveDelete"
    >
      <b-card-text
        v-if="type === 'clear-all'"
        class="text-title"
      >
        Do you want to clear all the list? </b-card-text>

      <b-card-text
        v-if="type === 'user' && deleteUser"
        class="text-title"
      >
        {{ `Do you want to delete ${deleteUser.username} from the list?` }} </b-card-text>
      <template #modal-ok>
        <!-- <Spinner v-if="loadingApprove" /> -->
        <span>Submit</span>
      </template>
    </b-modal>

    <!-- MODAL ADD USER LIST -->
    <b-modal
      id="user-list"
      ref="userList"
      title="Add Users"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitAddCode"
    >
      <b-card-text class="text-title">
        <!-- MODE -->
        <b-row class="mb-1 mt-2 d-flex align-items-start">
          <b-col
            cols="12"
            class="font-weight-bold"
          >
            <span>Add list Users join comp </span>
          </b-col>
          <b-col
            cols="12"
            class="font-weight-bold"
          >
            <ChooseListUser
              modal-type="add"
              @update-data="handleAddUserList"
              @upload-file="handleGetDataUpload"
            />
          </b-col>

        </b-row>

      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Save list</span>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCol, BPagination, BRow, BTable, VBModal, BButton, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
import { parse } from 'csv-parse'
// import { objectProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import ChooseListUser from '../settings/ChooseListUser.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BTable,
    BPagination,
    Spinner,
    ChooseListUser,
    BButton,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  // props: {
  //   dataComp: objectProp(),
  // },
  data() {
    return {
      listSeletedUser: [],

      csvData: [],
      formatCsvData: [],
      deleteType: null,
      type: null,
      deleteUser: null,
      exportData: [],
    }
  },
  computed: {
    ...mapState({
      list: s => s.races.list,
    }),
    ...mapState('races/entryCode', ['total', 'loadingGetUser']),
    ...mapState('races/compDetail', ['dataDetail']),
  },
  watch: {
    totalParticipant() {
      this.fetchListData()
    },
  },
  async created() {
    // this.contentPopup = await this.getSetting('flash-banner-for-free-quick-pick')
    // this.hideModalContent()
    // this.fetchListData()
  },
  methods: {
    ...mapActions({
      getListUserInWhiteList: 'races/entryCode/getListUserInWhiteList',
      handleAddUserCompCode: 'races/entryCode/handleAddUserCompCode',
      handleRemoveUserCompCode: 'races/entryCode/handleRemoveUserCompCode',
      handleAddCode: 'races/entryCode/handleAddCode',
      getCompDetail: 'races/compDetail/getCompDetail',
    }),
    async fetchParticipant() {
      const data = await this.getListUserInWhiteList({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: Number(this.$route.params.id),
      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalParticipant = this.total

      return data
    },
    async fetchListData() {
      const x = await this.getListUserInWhiteList({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: Number(this.$route.params.id),
        withAllData: true,
      })
      this.exportData = x
    },
    async handleSubmitApproveDelete() {
      if (this.type === 'clear-all') {
        const res = await this.handleRemoveUserCompCode({
          competitonId: this.$router.currentRoute.params.id,
          userIds: [],
        })
        if (res) {
          this.refetchData()
          this.handleAddCode({
            competitonId: this.$router.currentRoute.params.id,
            isEnabledWhitelistUser: false,
          })
          this.$refs.modal.hide()
        }
      }
      if (this.type === 'user') {
        const res = await this.handleRemoveUserCompCode({
          competitonId: this.$router.currentRoute.params.id,
          userIds: this.deleteUser.userId,
        })
        if (res) {
          if (this.totalParticipant === 1) {
            this.handleAddCode({
              competitonId: this.$router.currentRoute.params.id,
              isEnabledWhitelistUser: false,
            })
          }
          this.refetchData()
          this.$refs.modal.hide()
          this.showToastSuccess('Success', 'Delete this user from  the list successfully!')
        }
      }
    },
    splitArray(array) {
      const result = []
      const length = array.length
      for (let i = 0; i < length; i += 200) {
        result.push(array.slice(i, i + 200))
      }
      return result
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const newList = data.map(i => ({
          username: i[1],
          userId: i[0],
        }))
        this.csvData = newList

        if (newList.length > 200) {
          const splitArrays = this.splitArray(newList)
          this.formatCsvData = splitArrays
        } else {
          this.formatCsvData = []
        }
      })
    },
    handleShowModalDeleteGroup(item) {
      this.deleteUser = item
      this.type = 'user'
      this.$refs.modal.show()
    },
    handleAddUserList(data) {
      this.listSeletedUser = data
    },
    handleGetDataUpload(data) {
      this.csvData = data

      if (data.length > 200) {
        const splitArrays = this.splitArray(data)
        this.formatCsvData = splitArrays
      } else {
        this.formatCsvData = []
      }
    },
    async handleSubmitAddCode(event) {
      event.preventDefault()

      if (!this.dataDetail.competitionAdvance || !this.dataDetail.competitionAdvance.isEnabledWhitelistUser) {
        await this.handleAddCode({
          competitonId: this.$router.currentRoute.params.id,
          isEnabledWhitelistUser: true,
        })
        this.getCompDetail(this.$route.params.id)
      }

      // HAVE USER FILE
      if (this.csvData.length > 0) {
        if (this.formatCsvData.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.formatCsvData) {
            const userIds = item.map(x => x.userId)
            // eslint-disable-next-line no-await-in-loop
            const result = await this.handleAddUserCompCode({
              competitonId: this.$router.currentRoute.params.id,
              userIds,
            })
            if (!result) return
          }

          this.showToastSuccess('Success', 'Add users successfully!')
          this.resetData()

          return
        }
        const userIds = this.csvData.map(i => i.userId)
        const result = await this.handleAddUserCompCode({
          competitonId: this.$router.currentRoute.params.id,
          userIds,
        })
        if (!result) return
        this.resetData()
        return this.showToastSuccess('Notification', 'Add users successfully!')
      }

      // LIST USERS
      const userIds = this.listSeletedUser.map(i => i.userId)

      const result = await this.handleAddUserCompCode({
        competitonId: this.$router.currentRoute.params.id,
        userIds,
      })
      if (!result) return
      this.resetData()
      this.showToastSuccess('Notification', 'Add users successfully!')
    //   }
    },
    resetData() {
      this.$refs.userList.hide()
      this.refetchData()
      this.listSeletedUser = []
      this.csvData = []
      this.formatCsvData = []
    },
    handleClearAll() {
      this.type = 'clear-all'
      this.$refs.modal.show()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    // Use toast
    const refParticipantListTable = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'userId' },
      { key: 'username' },
      { key: 'action' },
    ]

    const perPage = ref(10)
    const totalParticipant = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]

    const dataMeta = computed(() => {
      const localItemsCount = refParticipantListTable.value ? refParticipantListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalParticipant.value,
      }
    })

    const refetchData = () => {
      refParticipantListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    return {
      formatDate,
      showToastError,
      showToastSuccess,

      tableColumns,
      perPage,
      currentPage,
      totalParticipant,
      dataMeta,
      perPageOptions,
      refParticipantListTable,
      refetchData,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .active-title {
    color: white;
  }

  .text-title {
    color: #000;
  }
  .w-full{
    width: 100%;
    display: block;
  }
  </style>
