var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('b-card',[_c('b-table',{ref:"refEventsCompTable",attrs:{"items":_vm.listRace,"fields":_vm.tableColumns,"responsive":"","show-empty":"","primary-key":"id"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.meetingsDated.length === 0)?_c('div',{staticClass:"text-center my-2"},[_vm._v(" No matching records found ")]):_vm._e()]},proxy:true},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_vm._v(" "+_vm._s(item.id)+" ")])])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.track.name)+" ")])])]}},{key:"cell(firstRaceStartTime)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateTimeLocal(item.races[0].startTime))+" ")])])]}},{key:"cell(lastRaceStartTime)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateTimeLocal(item.races[item.races.length - 1].startTime))+" ")])])]}},{key:"cell(raceType)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.tabIndicator)+" ")])])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalRaces,"per-page":10,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }