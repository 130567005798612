<template>
  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-2">Status</label>
            <v-select
              style="width: 200px;"
              :value="currentStatus"
              :options="compTypeOptions"
              :reduce="val => val.value"
              @input="onChangeType"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 ml-2"
              @click="showModal('approve-all')"
            >
              <template> Approve All Pending </template>
            </b-button>
          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refCompListTable"
        :items="fetchBackList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center text-white my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-if="fetchBackList.length === 0"
            class="text-center text-white my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: parentBetYourselfId -->
        <template #cell(parentBetYourselfId)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-extrabold"
              style="min-width: 100px;"
            >
              {{ `${item.parentBetYourselfId}-${item.betYourselfMarket}` }}
            </div>
          </b-media>
        </template>

        <!-- Column: betYourselfMarket -->
        <template #cell(betYourselfMarket)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ item.betYourselfMarket === 0 ? '0 winners' : `Any ${item.betYourselfMarket} or more` }}
            </div>
          </b-media>
        </template>

        <!-- Column: ID -->
        <template #cell(id)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold id-column">
              {{ item.groupId ? '' : item.userId }}
            </div>
          </b-media>
        </template>
        <!-- Column: user name -->
        <template #cell(name)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.groupId ? '' : item.username }}
            </div>
          </b-media>
        </template>

        <!-- Column: odds -->
        <template #cell(odds)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-extrabold">
              ${{ item.odds.toFixed(2) }}
            </div>
          </b-media>
        </template>

        <!-- Column: stake -->
        <template #cell(stake)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-extrabold">
              ${{ item.stake.toFixed(2) }}
            </div>
          </b-media>
        </template>

        <!-- Column: originalStake -->
        <template #cell(originalStake)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-extrabold">
              ${{ item.originalStake.toFixed(2) }}
            </div>
          </b-media>
        </template>

        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="item.stake !== item.originalStake && item.status === 'active' ? 'light-primary' :resolveStatusVariant(item.status)"
            >
              {{ item.stake !== item.originalStake && item.status === 'active' ? 'Partial' : resolveStatusText(item.status) }}
            </b-badge>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="{ item }">
          <div
            v-if="item.status !== 'declined'"
            style="cursor: pointer;"
            @click="handleShowModal(item)"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
            />
          </div>
        </template>
        <!-- Column: Detail -->

        <template #cell(detail)="row">
          <b-button
            variant="primary"
            size="sm"
            class="mr-2"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-button>

        </template>

        <template #row-details="row">
          <b-card class="border">
            <b-row
              cols="12"
              class="d-flex "
              style="flex-direction: column;"
            >
              <b-col cols="8">
                <div
                  v-for="i in formatBetslip(row.item)"
                  :key="i.entryId"
                  :style="formatStyle(i)"
                  class="mb-1"
                >
                  <div>

                    <p
                      class="m-0"
                      style="font-weight: bold;"
                    >
                      {{ i.name }}
                    </p>
                    <p>{{ i.leg }}</p>

                  </div>
                  <div
                    class="d-flex"
                    style="align-items: center; gap: 5px;"
                  >
                    <b-img
                      :src="i.img"
                      :alt="i.name"
                      height="30px"
                    />
                    <p class="m-0">
                      {{ i.selectionName }}
                    </p>
                  </div>
                  <p
                    class="m-0 border d-flex mt-1"
                    style="height: fit-content; padding-left: 10px; padding-right: 10px; min-width: 100px; justify-content: center;"
                  >
                    {{ i.currentWin ? i.currentWin.toFixed(2) : 'N/A' }} / {{ i.currentPlace ? i.currentPlace.toFixed(2) : 'N/A' }}
                  </p>
                </div>

              </b-col>
            </b-row>

          </b-card>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} items
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBacks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @ok="handleSubmitModal"
    >
      <b-card-text>
        <h6>{{ modal.content }}</h6>
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isLoading" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!-- CHANGE STATUS MODAL -->

    <b-modal
      ref="back"
      title="Change Status"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="md"
      @hide="hideModal"
    >
      <section style="color: black">
        <!-- <b-row> -->
        <h5
          class="mb-2"
          style="color: black;"
        >
          Please select one status:
        </h5>

        <b-form-radio-group
          v-model="selectedType"
          :options="optionsType"
          name="radio-inline"
          style="display: flex"
        />
        <b-row
          v-if="selectedType === 'partial'"
          class="mt-2"
        >
          <b-col
            cols="5"
            class="d-flex align-items-center"
            style="flex-direction: column;"
          >
            <div class="font-weight-bold">
              New accepted stake
            </div>
            <small>
              Current stake: ${{ selectedItem.stake.toFixed(2) }}
            </small>
            <small>
              Current odds: ${{ selectedItem.odds.toFixed(2) }}
            </small>
          </b-col>
          <b-col
            cols="6"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="acceptedStake"
              placeholder="eg. 1000"
              :formatter="formatNumberPrize"
            />
            <small
              v-if="errNewStake"
              class="text-danger"
            >New stake need to be smaller than ${{ selectedItem.stake.toFixed(2) }} </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingChangeStatus"
              @click="handleSaveChangeStatus"
            >
              <Spinner v-if="loadingChangeStatus" />
              <template v-else>
                Save
              </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>

  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import UserEnum, { UserAction, UserClosed } from '@core/utils/enum/user'
import { avatarText } from '@core/utils/filter'
import { formatEventDate, formatDateTimeAustralia, formatTime } from '@/helpers/utils'
import {
  // BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BFormRadioGroup,
  BFormInput,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'
import Spinner from '@core/components/Spinner'
import socket from '@/services/socket'
import useBackList from './useBackList'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BPagination,
    vSelect,
    // VerifyIcon,
    Spinner,
    BFormRadioGroup,
    BFormInput,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      UserClosed,
      action: {
        type: '',
        id: '',
      },
      modal: {
        title: '',
        content: '',
      },
      UserAction,
      formatDateTimeAustralia,
      selectedItem: null,
      optionsType: [
        { text: 'Accept', value: 'accept' },
        { text: 'Decline', value: 'decline' },
        { text: 'Partial', value: 'partial' },
      ],
      selectedType: null,
      acceptedStake: null,

      connected: false,
      socket: null,
      errNewStake: false,
    }
  },

  computed: {
    ...mapState('races/listBack', [
      'loading', 'loadingChangeStatus',
    ]),
    ...mapState('auth', ['user']),
    ...mapState('races/compDetail', ['dataDetail']),
    isLoading() {
      return this.loadingSetFeature || this.loadingDeleteComp
    },

  },
  watch: {
    acceptedStake(val) {
      if (val >= this.selectedItem.stake && !this.errNewStake) this.errNewStake = true
      if (val < this.selectedItem.stake && this.errNewStake) this.errNewStake = false
    },
  },
  mounted() {
    socket.on('bet-yourself/new', () => {
      this.refetchData()
    })
    if (this.dataDetail) {
      if (new Date(this.dataDetail.morningLockout) <= new Date()) {
        this.currentStatus = 'all'
      }
    }
  },
  methods: {
    ...mapActions({
      getListBack: 'races/listBack/getListBack',
      changeStatusBackItem: 'races/listBack/changeStatusBackItem',
      approveAllPendingList: 'races/listBack/approveAllPendingList',
    }),
    async fetchBackList() {
      if (!this.$route.params.id) return
      if (!this.$route.params.id && this.$route.name === 'comp-view' && this.dataDetail.competitionAdvance && this.dataDetail.competitionAdvance.betYourselfSetting) return
      const data = await this.getListBack({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: this.$route.params.id,
        ...(this.currentStatus !== 'all' && { status: this.currentStatus }),

      })

      if (!data && data.data.length <= 0) return this.showToastError(false, 'Error fetching comp list')
      this.totalBacks = data.pagination.total

      return data.data
    },

    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type, id) {
      this.action = { id, type }
      if (type === 'approve-all') {
        this.setModal('Approve', 'Are you sure you want to approve all pending list?')
      }
      this.$refs.modal.show()
    },

    async handleSubmitModal(event) {
      event.preventDefault()
      const { type } = this.action
      if (type === 'approve-all') {
        const res = await this.approveAllPendingList({
          competitionId: this.$route.params.id,

        })
        if (!res) return
        if (!this.loadingChangeStatus) {
          this.$nextTick(() => {
            this.refetchData()
            this.$refs.modal.hide()
            this.showToastSuccess('Success', 'Approve pending list is successfully!')
          })
        }
      }
    },
    onChangeType(selected) {
      this.currentStatus = selected
    },
    handleShowModal(item) {
      if (item.status === 'declined') return
      this.selectedItem = item
      this.$refs.back.show()
    },
    hideModal() {
      this.acceptedStake = null
      this.selectedItem = null
      this.selectedType = null
    },
    async handleSaveChangeStatus() {
      if (this.errNewStake) return
      if (this.selectedType === 'partial' && !this.acceptedStake) return this.showToastError('Error', 'Please enter new stake!')
      if (!this.selectedItem) return
      const res = await this.changeStatusBackItem({
        competitionUserId: this.selectedItem.competitionUserId,
        betYourselfMarket: this.selectedItem.betYourselfMarket,
        action: this.selectedType,
        newStake: this.selectedType === 'partial' ? this.acceptedStake : 123,
        parentBetYourselfId: this.selectedItem.parentBetYourselfId,
      })
      if (res) {
        this.refetchData()
        this.$refs.back.hide()
        this.$emit('reload')
        this.showToastSuccess('Success', 'Update status successfully!')
      }
    },
    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatBetslip(item) {
      const legs = this.dataDetail.legs.map((i, ind) => {
        const selection = item.selections.find(sel => sel.entryId === i.entryId)
        return {
          name: `${i.meetingName} Race ${i.raceNumber}`,
          leg: `Leg ${ind + 1} @ ${formatTime(i.startTime, 'HH:mm dd-MM-yyyy')}`,
          img: selection.silk,
          selectionName: `${selection.runnerNumber}. ${selection.name}`,
          currentPlace: selection.currentPlace,
          currentWin: selection.currentWin,
          position: selection.position,

        }
      })
      return legs
    },
    formatStyle(item) {
      return `display: flex; justify-content: space-between; background: ${item.position === 1 ? '#EFEFEF' : 'none'}`
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const roleId = UserEnum.UserRoleEnum.SuperAdmin
    const {
      tableColumns,
      perPage,
      currentPage,
      totalBacks,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refCompListTable,
      sortBy,
      startDateFilter,
      endDateFilter,
      refetchData,

      compTypeOptions,
      currentStatus,

      resolveStatusText,
      resolveStatusVariant,

    } = useBackList()

    return {
      getUserData,
      roleId,
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalBacks,
      dataMeta,
      perPageOptions,
      searchQuery,
      refCompListTable,
      isSortDirDesc,
      sortBy,
      refetchData,
      // Filter
      avatarText,

      showToastError,
      showToastSuccess,

      isRoleSuperAdmin,
      formatEventDate,

      startDateFilter,
      endDateFilter,

      formatTime,

      compTypeOptions,
      currentStatus,

      resolveStatusText,
      resolveStatusVariant,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
