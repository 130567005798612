<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mb-1"
        >
          <b-button
            v-if="!dataDetail.competitionAdvance || !dataDetail.competitionAdvance.code"
            v-b-modal.entry-code
            variant="primary"
            class="mb-1 mb-sm-0 mr-1"
            :disabled="dataDetail.status === 'abandon'"
          >
            <template> Set Entry Code</template>
          </b-button>

          <b-button
            v-b-modal.modal-abandon
            :variant="dataDetail.status === 'abandon' ? '' : 'danger'"
            class="mb-1 mb-sm-0 mr-1"
            :disabled="dataDetail.status === 'abandon'"
          >
            <template> Abandon Showdown</template>
          </b-button><b-button
            :variant="dataDetail.isCompleted ? '' : 'danger'"
            class="mb-1 mb-sm-0 mr-1"
            :disabled="dataDetail.isCompleted"
            @click="handleShowModal(submitType.endComp)"
          >
            <template> End Showdown</template>
          </b-button>
        </b-col>
      </b-row>
      <!-- <Spinner v-if="loadingEndComp" /> -->

      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="KeyIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">General</span>
          </template>

          <GeneralCompTab class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Setting -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Setting</span>
          </template>

          <setting-comp class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: ENTRY CODE -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Entry Code</span>
          </template>

          <EntryCodeView class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab:  YNY Mates -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="BookmarkIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">YNY Mates</span>
          </template>

          <YNYMateTab
            class="mt-2 pt-75"
          />
        </b-tab>
        <!-- Tab: Events -->
        <b-tab v-if="dataDetail.type !=='sport'">
          <template #title>
            <feather-icon
              icon="BookmarkIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Events</span>
          </template>

          <EventsCompTab
            :entries-list="dataDetail.legs"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Free Betslip -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="FilePlusIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Free Betslip</span>
          </template>

          <TabFreeBetslip
            class="mt-2 pt-75"
            :data-comp="dataDetail"
          />
        </b-tab>
        <!-- Tab: Participants -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Participants</span>
          </template>

          <ParticipantsCompTab class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Prizes -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Main Prizes</span>
          </template>

          <PrizesCompTab
            :is-refresh="loadingEndComp"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Front Prizes -->
        <b-tab v-if="dataDetail.type !=='sport' && dataDetail.entryPrizes.length > 0">
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Front Prizes</span>
          </template>

          <FrontPrizeTab
            :is-refresh="loadingEndComp"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Back Prizes -->
        <b-tab v-if="dataDetail.type !=='sport' && dataDetail.entryPrizes.length > 0">
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Back Prizes</span>
          </template>

          <BackPrizeTab
            :is-refresh="loadingEndComp"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Back+ Bet -->
        <b-tab v-if="dataDetail.competitionAdvance && dataDetail.competitionAdvance.betYourselfSetting">
          <template #title>
            <div style="position: relative;">
              <feather-icon
                icon="PocketIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Back+ Bet</span>
              <div
                v-if="totalback"
                style="padding: 3px; border-radius: 100%; background: red; position: absolute; top: -15px; right: -20px; font-size: 12px; color: white; font-weight: 600; padding-left: 5px; padding-right: 5px;"
              >
                {{ totalback }}
              </div>
            </div>
          </template>

          <BackList
            class="mt-2 pt-75"
            @reload="handleReload"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <b-modal
      id="modal-abandon"
      ref="modalAbandonComp"
      title="Confirm Abandon Showdown"
      centered
      cancel-variant="outline-secondary"
      @ok="handleAbandonShowdown"
      @hidden="handleHideModal"
    >
      <b-card-text class="text-title">
        <b-form @submit="onSubmit">
          <b-form-input
            hidden
            type="text"
            autocomplete="username"
          />
          <label
            for="password"
            class="w-full"
          >
            Enter your password

            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordConfirm"
                autocomplete="new-password"
                @update="onChange()"
              />

              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconConfirm"
                  class="cursor-pointer"
                  @click="togglePasswordConfirm"
                />
              </b-input-group-append>
            </b-input-group>
            <span
              v-if="isErrorRequired"
              class="text-danger"
            >Password is required</span>
          </label>
        </b-form>
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!-- MODAL ADD ENTRY CODE -->
    <b-modal
      id="entry-code"
      ref="entryCode"
      title="Add Entry Code"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitAddCode"
    >
      <b-card-text class="text-title">
        <!-- CODE -->
        <b-row>
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Code
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="code"
              type="text"
              placeholder="Enter code..."
            />
          </b-col>
        </b-row>
        <small
          v-if="errorCode"
          class="text-danger"
        >{{ errorName }}</small>

      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard, BTab, BTabs, BButton, VBModal, BCardText, BFormInput, BInputGroup, BInputGroupAppend, BForm, BRow, BCol,
} from 'bootstrap-vue'
import { ENTRY_TYPE_BACK, ENTRY_TYPE_FRONT } from '@/@core/utils/enum/comp'
import Spinner from '@core/components/Spinner'
import useToastHandler from '@/services/toastHandler'
import socket from '@/services/socket'
import GeneralCompTab from './GeneralCompTab'
import EventsCompTab from './EventsCompTab'
import ParticipantsCompTab from './ParticipantsCompTab'
import PrizesCompTab from './PrizesCompTab'
import FrontPrizeTab from './FrontPrizeTab'
import BackPrizeTab from './BackPrizeTab'
import YNYMateTab from './YNYMateTab.vue'
import TabFreeBetslip from './TabFreeBetslip.vue'
// import ChooseListUser from '../settings/ChooseListUser.vue'
import EntryCodeView from './EntryCodeView.vue'
import SettingComp from './SettingComp.vue'
import BackList from './back-bet/BackList.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    GeneralCompTab,
    EventsCompTab,
    ParticipantsCompTab,
    PrizesCompTab,
    LoadingPage,
    // BLink,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    Spinner,
    BCardText,
    FrontPrizeTab,
    BackPrizeTab,
    BFormInput,
    BRow,
    BCol,

    YNYMateTab,
    TabFreeBetslip,
    // ChooseListUser,
    EntryCodeView,
    SettingComp,
    BackList,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      modal: {
        title: '',
        content: '',
      },
      submitType: {
        abandon: 1,
        endComp: 2,
      },
      type: null,
      password: null,
      isErrorRequired: false,
      passwordConfirm: 'password',

      code: null,
      errorCode: '',
      listSeletedUser: [],

      csvData: [],
      formatCsvData: [],
      totalback: null,
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('races/endComp', ['loadingEndComp']),
    isSubmitting() {
      return this.loadingEndComp
    },
    passwordToggleIconNew() {
      return this.passwordConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    switch (this.$route.query.tab) {
      case 'transaction-history':
        this.tabIndex = 1
        break
      case 'deposit-history':
        this.tabIndex = 2
        break

      default:
        break
    }
    this.loading = true
    await this.getCompDetail(this.$route.params.id)
    this.loading = false
    if (this.dataDetail.competitionAdvance && this.dataDetail.competitionAdvance.betYourselfSetting) this.getTotalBack()

    socket.on('connect', () => {
      this.connected = true
    })
    socket.on('reconnect', () => {
      console.log('Reconnected to the server')
    })

    socket.emit('comp/listen', {
      competitionId: 0,
    })

    socket.on('bet-yourself/new', () => {
      this.getTotalBack()
    })
  },

  methods: {
    ...mapActions({
      getCompDetail: 'races/compDetail/getCompDetail',
      endCompAsync: 'races/endComp/endCompAsync',
      abandonComp: 'races/endComp/abandonComp',
      handleAddCode: 'races/entryCode/handleAddCode',
      handleAddUserCompCode: 'races/entryCode/handleAddUserCompCode',
      getListBack: 'races/listBack/getListBack',
    }),
    onSubmit(event) {
      event.preventDefault()
    },
    onChange() {
      this.isErrorRequired = false
    },
    handleHideModal() {
      this.password = null
      this.passwordConfirm = 'password'
    },
    togglePasswordConfirm() {
      this.passwordConfirm = this.passwordConfirm === 'password' ? 'text' : 'password'
    },

    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },
    handleShowModal(type) {
      this.type = type
      if (type === this.submitType.endComp) {
        this.setModal('End Showdown', 'Do you want to end this showdown?')
      }
      this.$refs.modal.show()
    },
    async handleAbandonShowdown(event) {
      event.preventDefault()

      if (!this.password) {
        this.isErrorRequired = true
        return
      }

      const res = await this.abandonComp({
        id: this.$router.currentRoute.params.id,
        password: this.password,
      })
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Abandon showdown successfully')
      }
      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modalAbandonComp.hide()
        })
      }
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      if (this.type === this.submitType.endComp) {
        const res = await this.endCompAsync(this.$router.currentRoute.params.id)
        if (res) {
          this.getCompDetail(this.$route.params.id)
          this.showToastSuccess('Success', 'End showdown successfully')
        } else {
          this.showToastError('Error', 'End showdown failed')
        }
      }

      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    async handleSubmitAddCode(event) {
      event.preventDefault()

      const res = await this.handleAddCode({
        competitonId: this.$router.currentRoute.params.id,
        code: this.code,
        isEnabledWhitelistUser: false,
      })
      if (res) {
        this.$refs.entryCode.hide()
        this.code = null
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Notification', 'Add code successfully!')
      }
    },
    async getTotalBack() {
      console.log('object', !!this.$route.params.id)
      if (!!this.$route.params.id && this.$route.name === 'comp-view' && this.dataDetail.competitionAdvance && this.dataDetail.competitionAdvance.betYourselfSetting && Number(this.$route.params.id) === this.dataDetail.competitionId) {
        const data = await this.getListBack({
          limit: 1,
          page: 1,
          competitionId: this.$route.params.id,
          status: 'pending',

        })
        this.totalback = data.pagination.total

        return data.data
      }
      return []
    },
    handleReload() {
      this.getTotalBack()
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      ENTRY_TYPE_FRONT,
      ENTRY_TYPE_BACK,
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

<style lang="scss" scoped>
.active-title {
  color: white;
}

.text-title {
  color: #000;
}
.w-full{
  width: 100%;
  display: block;
}
</style>
