import { computed, ref, watch } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'

export default function useCompList() {
  // Use toast
  const refCompListTable = ref(null)
  const startDateFilter = ref(null)
  const endDateFilter = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'action' },
    { key: 'parentBetYourselfId', label: ' betid' },
    { key: 'competitionUserId', label: 'original betid' },
    { key: 'competitionId', label: 'comp id' },
    { key: 'betYourselfMarket', labe: 'market' },
    { key: 'groupId' },
    { key: 'groupName' },
    { key: 'id', label: 'user id' },
    { key: 'name', label: 'user name' },
    { key: 'odds' },
    { key: 'originalStake' },
    { key: 'stake' },
    { key: 'result' },
    { key: 'status' },
    { key: 'detail', label: 'Show Details' },
  ]

  const perPage = ref(100)
  const totalBacks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const compTypeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Pending', value: 'pending' },
    { label: 'Active', value: 'active' },
    { label: 'Declined', value: 'declined' },
  ]

  const resolveStatusText = status => {
    if (status === 'pending') return 'Pending'
    if (status === 'active') return 'Active'
    if (status === 'declined') return 'Declined'
    if (status === 'approved') return 'Approved'
    if (status === 'transferred') return 'Transferred'

    // return 'primary'
  }

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'light-warning'
    if (status === 'active') return 'light-success'
    if (status === 'transferred') return 'light-success'
    if (status === 'approved') return 'light-info'
    return 'light-danger'
  }

  const currentStatus = ref('pending')

  const dataMeta = computed(() => {
    const localItemsCount = refCompListTable.value ? refCompListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBacks.value,
    }
  })

  const refetchData = () => {
    refCompListTable.value.refresh()
  }

  watch([currentPage, perPage, currentStatus], () => {
    refetchData()
  })
  watch([endDateFilter, startDateFilter], () => {
    if (endDateFilter && startDateFilter) { refetchData() }
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalBacks.value = 0
      refetchData()
      // eslint-disable-next-line comma-dangle
    }, 700)
  )

  return {
    tableColumns,
    perPage,
    currentPage,
    totalBacks,
    dataMeta,
    perPageOptions,
    searchQuery,
    refCompListTable,
    isSortDirDesc,
    sortBy,
    startDateFilter,
    endDateFilter,
    refetchData,

    compTypeOptions,
    currentStatus,

    resolveStatusText,
    resolveStatusVariant,

  }
}
