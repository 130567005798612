<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-row>
        <b-col
          cols="12"
          class="order-1 order-lg-0"
        >
          <div>
            <b-row>
              <b-col md="6">
                <p><span class="title">Competition ID:</span> {{ dataDetail.competitionId }}</p>
                <p v-if="dataDetail.competitionAdvance && dataDetail.competitionAdvance.code">
                  <span
                    class="title"
                  >Entry Code:</span>
                  <b-badge
                    pill
                    variant="success"
                    class="ml-2"
                    style="font-size: 16px;"
                  >{{ dataDetail.competitionAdvance.code }}</b-badge>
                </p>
                <p><span class="title">Competition Name (logged in):</span> {{ dataDetail.name }}</p>
                <p><span class="title">Competition Name (not logged in):</span> {{ dataDetail.guestName }}</p>
                <p><span class="title">Real Cash Betslips:</span> {{ compStatistic.cashBetSlip }}</p>
                <p><span class="title">Bonus Cash Betslips:</span> {{ compStatistic.bonusBetSlip }}</p>
                <p><span class="title">Free Betslips:</span> {{ compStatistic.freeBetSlip }}</p>
                <p><span class="title">Mixed Betslips:</span> {{ compStatistic.mixedBetSlip }}</p>
                <p><span class="title">Total Betslips:</span> {{ dataDetail.participants }}</p>
                <p><span class="title">Flexi Betslips:</span> {{ compStatistic.flexiBetSlip }}</p>
                <p><span class="title">Participants Unique:</span> {{ dataDetail.uniqueParticipants }}</p>
                <p><span class="title">Percent Winner:</span> {{ dataDetail.percentWinner }}</p>
                <p><span class="title">Featured:</span> {{ dataDetail.featured }}</p>
                <p><span class="title">Completed:</span> {{ dataDetail.isCompleted }}</p>
                <p><span class="title">Status:</span> {{ dataDetail.status }}</p>
              </b-col>
              <b-col md="6">
                <p>
                  <span class="title">First Leg Start Date/Time:</span>
                  {{ formatTime(dataDetail.competitionDate, 'HH:mm aa dd-MM-yyyy') }}
                </p>
                <p><span class="title">Racing Legs:</span> {{ dataDetail.entryCount }}</p>
                <p>
                  <span class="title">Showdown Open Date/Time: </span>
                  {{ formatTime(dataDetail.openForEntry, 'HH:mm aa dd-MM-yyyy') }}
                </p>
                <p>
                  <span class="title">End Date:</span>
                  {{ formatTime(dataDetail.endDate, 'HH:mm aa dd-MM-yyyy') }}
                </p>

                <p><span class="title">Price:</span> {{ dataDetail.price }}</p>
                <p><span class="title">Late Price:</span> {{ dataDetail.latePrice }}</p>
                <p><span class="title">Limit betslip:</span> {{ dataDetail.joinTimesLimit }}</p>
                <p><span class="title">Manual Betslip Entry:</span> {{ dataDetail.manualBetSlipEntry | 0 }}</p>
                <p><span class="title">Bonus additional ticket:</span> {{ dataDetail.apiPrice | 0 }}</p>
              </b-col>
            </b-row>

            <!-- <p><span class="title">Descriptions:</span> {{ dataDetail.competition.descriptions }}</p> -->
            <p><span class="title">Description Information:</span> {{ dataDetail.descriptionInfo }}</p>
            <p><span class="title">More Information:</span> {{ dataDetail.moreInfo }}</p>
            <p><span class="title">Remember:</span> {{ dataDetail.remember }}</p>

            <p><span class="title">Scratchings:</span> {{ dataDetail.scratchings }}</p>
            <p v-if="dataDetail.flexiValues && dataDetail.flexiValues.length > 0">
              <span class="title">Flexi options:</span>
              <b-badge
                v-for="(i, ind) in formatFlexi"
                :key="ind"
                pill
                variant="light-primary"
                style="margin-left: 4px; font-size: 14px"
              >
                {{ i }}
              </b-badge>
            </p>
            <!-- eslint-disable vue/no-v-html -->
            <!-- <p><span class="title">Participant Descriptions:</span></p>
            <p
              class="pl-3"
              v-html="dataDetail.competition.participantDescriptions"
            /> -->
            <!-- eslint-disable vue/no-v-html -->
            <!-- <p><span class="title">Challenge Play Rules:</span></p>
            <p
              class="pl-3"
              v-html="dataDetail.competition.challengePlayRules"
            /> -->
            <!-- <p><span class="title">Banner Website:</span></p>
            <b-img
              v-if="dataDetail.bannerUrl"
              class="banner-img ml-75"
              :src="dataDetail.bannerUrl"
              alt="logo"
            />

            <p><span class="title mt-2">Banner Mobile:</span></p>
            <b-img
              v-if="dataDetail.bannerUrl"
              class="banner-img ml-75 mb-2"
              :src="dataDetail.bannerMobileUrl"
              alt="logo"
            /> -->

            <p class="title">
              Use Flexi Roll Over Pool:
              <b-badge
                pill
                :variant="formatColor(dataDetail.useFlexiRollOverWallet)"
              >
                {{ dataDetail.useFlexiRollOverWallet ? 'YES' : 'NO' }}
              </b-badge>
            </p>

            <div class="d-flex align-items-center">
              <p class="title mr-2">
                Enable Jackpot:
                <b-badge
                  pill
                  :variant="formatColor(dataDetail.isJackpotEnabled)"
                >
                  {{ dataDetail.isJackpotEnabled ? 'YES' : 'NO' }}
                </b-badge>
              </p>
              <p class="title">
                Percentage to Add: {{ percentJackpot }}%

              </p>
            </div>

            <p class="title">
              Transfer Prize By
              <b-badge
                pill
                :variant="formatColorPrizeTransfer(dataDetail.prizeType)"
                style="margin-left: 10px;"
              >
                {{ dataDetail.prizeType }}
              </b-badge>
            </p>

            <p
              v-if="dataDetail.useFlexiRollOverWallet"
              class="title"
            >
              Roll Over Amount: <span style="font-weight: 400"> ${{ dataDetail.flexiRollOverAmount }}</span>
            </p>

            <p class="title">
              Prize From:
              <span style="font-weight: 400">{{ capitalizeFirstLetter(dataDetail.prizeMode) }}</span>
            </p>

            <template v-if="prizeGrouped.slice(0, 12).length">
              <p>
                <span class="title">
                  <template v-if="prizeGrouped.slice(0, 12).length > 1">Prize 1st to {{ prizeGrouped.slice(0, 12).length
                  }}{{ findDateOrder(prizeGrouped.slice(0, 12).length) }}:</template>
                  <template v-else>Prize 1st </template>
                </span>
              </p>

              <b-row>
                <b-col cols="3">
                  <ul>
                    <li
                      v-for="(item, index) in prizeGrouped.slice(0, 4)"
                      :key="index"
                    >
                      <b-row>
                        <b-col cols="4">
                          <template v-if="item.length <= 1">
                            {{ `${item[0].order}${findDateOrder(item[0].order)}` }}
                          </template>
                          <template v-else>
                            {{
                              `${item[0].order}${findDateOrder(item[0].order)} to ${item[item.length - 1].order
                              }${findDateOrder(item[item.length - 1].order)}`
                            }}
                          </template>
                        </b-col>
                        <b-col cols="8">
                          {{ formatCurrencyBuiltInFunc(item[0].value) }}
                        </b-col>
                      </b-row>
                    </li>
                  </ul>
                </b-col>
                <b-col cols="3">
                  <ul>
                    <li
                      v-for="(item, index) in prizeGrouped.slice(4, 8)"
                      :key="index"
                    >
                      <b-row>
                        <b-col cols="4">
                          <template v-if="item.length <= 1">
                            {{ `${item[0].order}${findDateOrder(item[0].order)}` }}
                          </template>
                          <template v-else>
                            {{
                              `${item[0].order}${findDateOrder(item[0].order)} to ${item[item.length - 1].order
                              }${findDateOrder(item[item.length - 1].order)}`
                            }}
                          </template>
                        </b-col>
                        <b-col cols="8">
                          {{ formatCurrencyBuiltInFunc(item[0].value) }}
                        </b-col>
                      </b-row>
                    </li>
                  </ul>
                </b-col>
                <b-col cols="3">
                  <ul>
                    <li
                      v-for="(item, index) in prizeGrouped.slice(8, 12)"
                      :key="index"
                    >
                      <b-row>
                        <b-col cols="4">
                          <template v-if="item.length <= 1">
                            {{ `${item[0].order}${findDateOrder(item[0].order)}` }}
                          </template>
                          <template v-else>
                            {{
                              `${item[0].order}${findDateOrder(item[0].order)} to ${item[item.length - 1].order
                              }${findDateOrder(item[item.length - 1].order)}`
                            }}
                          </template>
                        </b-col>
                        <b-col cols="8">
                          {{ formatCurrencyBuiltInFunc(item[0].value) }}
                        </b-col>
                      </b-row>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </template>

            <template v-if="prizeGrouped.slice(12, prizeGrouped.length).length">
              <p>
                <span class="title">
                  <template v-if="prizeGrouped.slice(12, prizeGrouped.length).length > 1">
                    Prize 13th to
                    {{
                      `${prizeGrouped
                        .slice(0, prizeGrouped.length)
                        .reduce((acc, item) => acc + item.length, 0)}${findDateOrder(
                        prizeGrouped.slice(0, prizeGrouped.length).reduce((acc, item) => acc + item.length, 0)
                      )}`
                    }}:
                  </template>
                  <template v-else> Prize 13th: </template>
                </span>
              </p>

              <b-row>
                <b-col
                  v-for="(item, index) in prizeGrouped.slice(12, prizeGrouped.length)"
                  :key="index"
                  cols="4"
                >
                  <b-row>
                    <b-col cols="4">
                      <template v-if="item.length <= 1">
                        {{ `${item[0].order}${findDateOrder(item[0].order)}` }}
                      </template>
                      <template v-else>
                        {{
                          `${item[0].order}${findDateOrder(item[0].order)} to ${item[item.length - 1].order
                          }${findDateOrder(item[item.length - 1].order)}`
                        }}
                      </template>
                    </b-col>
                    <b-col cols="8">
                      {{ formatCurrencyBuiltInFunc(item[0].value) }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>

            <p
              v-if="dataDetail.type === 'classic'"
              class="mt-2"
            >
              <span class="title">Front Prizes:</span>
            </p>

            <b-row>
              <b-col cols="6">
                <ul>
                  <li
                    v-for="(item, index) in filterFrontBackPrizes(0, 1, 5)"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="4">
                        {{ `${item.order}${findDateOrder(item.order)}` }}
                      </b-col>
                      <b-col cols="8">
                        {{ formatCurrencyBuiltInFunc(item.value) }}
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
              <b-col cols="6">
                <ul>
                  <li
                    v-for="(item, index) in filterFrontBackPrizes(0, 6, 10)"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="4">
                        {{ `${item.order}${findDateOrder(item.order)}` }}
                      </b-col>
                      <b-col cols="8">
                        {{ formatCurrencyBuiltInFunc(item.value) }}
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
            </b-row>

            <p
              v-if="dataDetail.type === 'classic'"
            ><span class="title">Back Prizes:</span></p>

            <b-row>
              <b-col cols="6">
                <ul>
                  <li
                    v-for="(item, index) in filterFrontBackPrizes(1, 1, 5)"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="4">
                        {{ `${item.order}${findDateOrder(item.order)}` }}
                      </b-col>
                      <b-col cols="8">
                        {{ formatCurrencyBuiltInFunc(item.value) }}
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
              <b-col cols="6">
                <ul>
                  <li
                    v-for="(item, index) in filterFrontBackPrizes(1, 6, 10)"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="4">
                        {{ `${item.order}${findDateOrder(item.order)}` }}
                      </b-col>
                      <b-col cols="8">
                        {{ formatCurrencyBuiltInFunc(item.value) }}
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
            </b-row>

            <!-- COMP RACE -->
            <p v-if="dataDetail.type === 'classic'">
              <span class="title">Entries:</span>
            </p>

            <b-row v-if="dataDetail.type === 'classic'">
              <b-col
                cols="12"
                lg="6"
              >
                <h6 class="mb-1 text-center">
                  MORNING LOCKOUT: {{ formatTime(dataDetail.morningLockout, 'HH:mm aa dd-MM-yyyy') }}
                </h6>
                <b-row
                  v-for="(item, key) in frontEntry"
                  :key="key"
                  class="mb-1"
                  align-v="center"
                >
                  <b-col cols="2">
                    LEG {{ key + 1 }} :
                  </b-col>
                  <b-col cols="10">
                    <p
                      v-if="item.meetingName && item.raceNumber"
                      class="border mb-0 px-2 race__name"
                    >
                      {{ item.meetingName }} R{{ item.raceNumber }}
                    </p>
                    <p
                      v-else
                      class="border mb-0 px-2 race__name"
                    >
                      {{ item.raceName }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <h6 class="mb-1 text-center">
                  AFTERNOON LOCKOUT: {{ formatTime(dataDetail.afternoonLockout, 'HH:mm aa dd-MM-yyyy') }}
                </h6>
                <b-row
                  v-for="(item, key) in backEntry"
                  :key="key"
                  class="mb-1"
                  align-v="center"
                >
                  <b-col cols="2">
                    LEG {{ key + 1 }} :
                  </b-col>
                  <b-col cols="10">
                    <p
                      v-if="item.meetingName && item.raceNumber"
                      class="border mb-0 px-2 race__name"
                    >
                      {{ item.meetingName }} R{{ item.raceNumber }}
                    </p>
                    <p
                      v-else
                      class="border mb-0 px-2 race__name"
                    >
                      {{ item.raceName }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <!-- COMP SPORT -->
            <p v-if="dataDetail.type === 'sport' && dataDetail.leagues.length > 0">
              <span class="title">League: {{ dataDetail.leagues[0].sportLeagueId }}</span>
            </p>
            <p v-if="dataDetail.type === 'sport'">
              <span class="title">Lockout leg: <span style="font-weight: normal;">{{ formatTime(dataDetail.lockoutLeg, 'HH:mm aa dd-MM-yyyy') }}</span> </span>
            </p>
            <!-- COMP SPORT -->
            <p v-if="dataDetail.type === 'sport'">
              <span class="title">Events:</span>
            </p>

            <b-row v-if="dataDetail.type === 'sport'">
              <b-col
                cols="12"
              >
                <b-row
                  v-for="(item, key) in frontEntrySport()"
                  :key="key"
                  class="mb-1"
                  align-v="center"
                >
                  <b-col cols="2">
                    LEG {{ key + 1 }} :
                  </b-col>
                  <b-col cols="10">
                    <div
                      class="border d-flex mb-0 px-2"
                      style="flex-direction: column; border-radius: 20px; padding-block: 5px;"
                    >
                      <span
                        v-if="item.eventTitle"
                        style="font-weight: bold"
                      > Event:</span>
                      <span>{{ item.eventTitle }}</span>
                      <span v-if="item.startTime"> ( {{ formatDateTimeLocal(item.startTime) }} )</span>
                      <span style="font-weight: bold">Stadium: <span style="font-weight: normal;">{{ item.stadium
                      }}</span></span>
                      <span style="font-weight: bold; margin-top: 4px;">Flag:</span>
                      <b-row>

                        <div
                          v-if="item.scores"
                          style="display: flex; gap: 10px; flex-direction: column; margin-left: 20px;"
                        >
                          <div
                            v-for="(role, stt) in item.scores"
                            :key="role.role"
                            style="margin-bottom: 2px; display: flex; align-items: center;"
                          >
                            <span>{{ `${stt + 1}. Role: ${role.role}` }}</span>

                            <b-img
                              v-if="role.flag"
                              style="width: 30px; height: 30px; margin-left: 10px;"
                              :src="role.flag"
                              alt="logo"
                            />
                            <b-img
                              v-if="role.subFlag"
                              style="width: 30px; height: 30px; margin-left: 10px;"
                              :src="role.subFlag"
                              alt="logo"
                            />

                            <span
                              v-if="role.teamAlias"
                              class="ml-2"
                            >TeamAlias: {{ role.teamAlias }}</span>

                          </div>
                        </div>
                      </b-row>

                      <span
                        v-if="item.markets"
                        style="font-weight: bold"
                      > Markets:</span>
                      <span
                        v-for="(i, ind) in item.markets"
                        :key="ind"
                      >
                        {{ `${ind + 1}, ${i.market.name}` }}
                        <b-img
                          v-if="i.imageUrl"
                          style="width: 25px; height: 25px; margin-left: 10px;"
                          :src="i.imageUrl"
                          alt="image"
                        />
                        <br>
                        <div
                          class="d-flex"
                          style="align-items: center; gap: 5px"
                        >
                          <span style="font-size: 12px">{{ `Number seleted: ${i.selectionLimit}` }}</span>
                        </div>
                      </span>

                      <span v-if="item.markets.find(i => i.isFeatured)"><span style="font-weight: bold;">FeaturedMarket:</span> {{ item.markets.find(i => i.isFeatured).market.name }} ({{ item.markets.find(i => i.isFeatured).displayName }})</span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <div
                v-if="loadingListMarket"
                style="display: flex; height: 200px; align-items: center; justify-content: center; width: 100%;"
              >
                <b-spinner
                  medium
                  variant="secondary"
                />
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BBadge, BSpinner,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import {
  formatDate,
  formatEventDate,
  formatCurrencyBuiltInFunc,
  formatTime,
  formatDateTimeLocal,
  capitalizeFirstLetter,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
// import MatchItem from './MatchItem'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BSpinner,
  },
  data() {
    return {
      listMarketEvent: [],
      loadingListMarket: false,
      percentJackpot: null,
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail', 'compStatistic', 'loadingStatistic']),
    frontEntry() {
      return this.dataDetail.legs.filter(entry => entry.entryType === 0)
    },
    backEntry() {
      return this.dataDetail.legs.filter(entry => entry.entryType === 1)
    },
    prizeGrouped() {
      const groupedData = []
      if (this.dataDetail.prizes.length) {
        let tempGroup = []

        for (let i = 0; i < this.dataDetail.prizes.length; i++) {
          const currentValue = this.dataDetail.prizes[i]
          const previousValue = this.dataDetail.prizes[i - 1]

          if (previousValue === undefined || currentValue?.value !== previousValue?.value) {
            if (tempGroup.length > 0) {
              groupedData.push(tempGroup)
            }
            tempGroup = []
          }

          tempGroup.push(currentValue)
        }

        if (tempGroup.length > 0) {
          groupedData.push(tempGroup)
        }
        return groupedData
      }
      return groupedData
    },
    formatFlexi() {
      const newList = this.dataDetail.flexiValues.map(i => `${i}%`)
      return newList
    },
  },
  async mounted() {
    const res = await this.getSetting('percent-of-bet-slip-to-jackpot')
    this.percentJackpot = res
  },
  async created() {
    await this.getCompStatistic(this.$route.params.id)
    if (this.$route.params.id) await this.getListMarket()
  },
  methods: {
    ...mapActions({
      getCompStatistic: 'races/compDetail/getCompStatistic',
      getListMarketOfSportComp: 'sportComp/createComp/getListMarketOfSportComp',
      getSetting: 'setting/setting/getSetting',
    }),
    handleSubmitModal() { },
    resetModal() { },
    filterPrizes(from, to) {
      return this.dataDetail.prizes.filter(prize => prize.order >= from && prize.order <= to)
    },
    filterFrontBackPrizes(type, from, to) {
      const list = this.dataDetail.entryPrizes.filter(i => i.entryType === type)
      return list.filter(prize => prize.order >= from && prize.order <= to)
    },
    findDateOrder(value) {
      if (value % 10 === 1 && value !== 11) {
        return 'st'
      }
      if (value % 10 === 2 && value !== 12) {
        return 'nd'
      }
      if (value % 10 === 3 && value !== 13) {
        return 'rd'
      }
      return 'th'
    },
    formatColor(val) {
      if (val) {
        return 'light-success'
      }
      return 'light-danger'
    },
    formatColorPrizeTransfer(val) {
      if (val === 'Cash') {
        return 'light-success'
      }
      return 'light-danger'
    },
    async getListMarket() {
      this.loadingListMarket = true
      const newListMarket = []
      const data = this.dataDetail.events
      for (let i = 0; i < data.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const res = await this.getListMarketOfSportComp({
          competitionId: this.$route.params.id,
          eventId: this.dataDetail.events[i].eventId,
        })
        const format = {
          markets: res,
          eventId: this.dataDetail.events[i].eventId,
        }
        newListMarket.push(format)
      }

      const formatData = data.map(event => {
        const item = newListMarket.find(x => (x.eventId === event.eventId))
        return {
          ...event,
          markets: item.markets,
        }
      })
      this.loadingListMarket = false
      this.listMarketEvent = formatData
    },
    frontEntrySport() {
      const list = this.listMarketEvent.filter(i => i.entryType === 0)
      if (list.length > 0) {
        const formatList = list.map(l => {
          const scores = [...l.scores].sort((a, b) => {
            if (a.role === 'HomeTeam') return -1
            if (b.role === 'HomeTeam') return 1
            return 0
          })
          return {
            ...l,
            scores,
          }
        })

        return formatList
      }
      return list
    },
  },
  setup() {
    const { showToastError } = useToastHandler()
    return {
      formatDate,
      showToastError,
      formatEventDate,
      formatCurrencyBuiltInFunc,
      formatTime,
      capitalizeFirstLetter,
      formatDateTimeLocal,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.title {
  font-weight: 700;
}

p.race__name {
  border-radius: 20px;
  padding-block: 5px;
}

ul {
  list-style: none;
  padding-left: 0;
}

.banner-img {
  max-width: 100%;
}
</style>
