<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card>

      <b-button
        variant="primary"
        class="mb-2"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        style="border-radius: 18px;"
        @click="handleShowListGroup"
      >
        <span> ADD GROUP TO COMP</span>
      </b-button>

      <b-table
        ref="refGroupTable"
        :items="fetchGroup"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="fetchGroup.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- Column: createdTime -->
        <template #cell(createdTime)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ formatTime(item.createdTime, 'HH:mm:ss dd-MM-yyyy') }}
            </div>
          </div>
        </template>
        <!-- Column: delete -->
        <template #cell(delete)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div
              class="d-flex align-items-center font-weight-extrabold"
              style="cursor: pointer;"
              @click="handleShowModalDeleteGroup(item)"
            >
              <feather-icon icon="TrashIcon" />

            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalGroup"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- MODAL ADD GROUP -->
    <b-modal
      ref="group"
      hide-footer
      title="List Group"
      centered
      cancel-variant="outline-secondary"
      size="md"
    >
      <b-row class="text-modal">
        <b-form-input
          v-model="searchQuery"
          type="text"
          placeholder="Search..."
        />
        <b-form-group
          class="container"
          style="margin-top: 10px;"
        >
          <b-form-checkbox
            v-for="item in groups"
            :key="item.groupId"
            v-model="selectedGroups"
            name="event-filter"
            :value="item"
            class="mb-1 ml-2"
            checked="true"
            :disabled="disabledSelectedGroup(item)"
          >
            {{ `${item.groupId}. ${item.name} (${item.totalUsers})` }}
          </b-form-checkbox>
        </b-form-group>

      </b-row>

      <div style="display: flex; align-items: center; gap: 10px; justify-content: center;">
        <feather-icon
          v-if="Number(page) > 1"
          icon="ChevronLeftIcon"
          size="18"
          style="color: black; cursor: pointer;"
          @click="handleMinusPage"
        />
        <feather-icon
          v-if="Number(page) === 1"
          icon="ChevronLeftIcon"
          size="18"
          style="color: #D9D9D9;"
        />
        <b-form-input
          id="page"
          v-model="page"
          type="number"
          style="padding: 6px; padding-left: 10px; padding-right: 10px; border-radius: 20px; font-weight: bold; background-color: #2B65A5; width: 50px; color: white; text-align: center;"
          :formatter="formatter"
        />
        <feather-icon
          v-if="Number(page) < totalPage"
          icon="ChevronRightIcon"
          size="18"
          style="color: black; cursor: pointer;"
          @click="handlePlusPage"
        />
        <feather-icon
          v-if="Number(page) === totalPage"
          icon="ChevronRightIcon"
          size="18"
          style="color: #D9D9D9;"
        />
      </div>
      <b-row>
        <b-col class="d-flex justify-content-end ">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleAddGroup"
          >
            <Spinner v-if="loadingDeleteGroup" />
            <span v-else>Add</span>
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
    <!-- MODAL DELETE GROUP -->
    <b-modal
      ref="delete-group"
      title="Delete"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loadingDeleteGroup"
      @ok="handleDeleteGroup"
    >
      <b-card-text style="color: black; font-weight: bold;">
        Do you want remove the group {{ groupDeleteId }} in list?
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingDeleteGroup" />
        <span v-else>Delete</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BPagination, BRow, BCol, BCard,
  BButton, VBModal,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BCardText,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { formatDate, formatDateTimeLocal, formatTime } from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import { arrayProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    entriesList: arrayProp(),
  },
  data() {
    return {
      groups: [],
      searchText: '',
      selectedGroups: [],
      page: 1,
      totalListAll: null,
      totalPage: 1,
      timeout: null,
      groupDeleteId: null,

      listGroupOfComp: [],
    }
  },
  computed: {
    ...mapState('races/listRace', ['tokenBetMaket', 'expireAt']),
    ...mapState('races/listGroup', ['loadingListGroup', 'loadingDeleteGroup']),
    searchQuery: {
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.page = 1
          this.searchText = val
          this.fetchListAllGroup()
        }, 500)
      },
    },
  },
  watch: {
    page() {
      this.fetchListAllGroup()
    },
  },
  async created() {
    this.groups = await this.fetchListAllGroup()
  },
  methods: {
    ...mapActions({
      getTokenBetMaker: 'races/listRace/getTokenBetMaker',
      getListGroup: 'races/listGroup/getListGroup',
      deleteCompGroup: 'races/listGroup/deleteCompGroup',
      addCompGroup: 'races/listGroup/addCompGroup',
    }),
    async fetchGroup() {
      const data = await this.getListGroup({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: this.$route.params.id,
      })

      if (!data && data.data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalGroup = data.pagination.total
      this.listGroupOfComp = data.data
      return data.data
    },
    async fetchListAllGroup() {
      if (!this.page) this.page = 1
      const data = await this.getListGroup({
        searchParams: this.searchText,
        limit: 10,
        page: this.page < 1 ? 1 : this.page,
      })

      this.totalListAll = data.pagination.total
      this.totalPage = Math.ceil(data.pagination.total / 10)
      this.groups = data.data
      return data.data
    },
    formatter(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }

        if (Number(value) > this.totalPage) return this.totalPage
        if (Number(value) < 1) return '1'
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleAddGroup() {
      const list = this.selectedGroups.map(i => i.groupId)
      const res = await this.addCompGroup({
        competitionId: this.$route.params.id,
        groupIds: list,
      })
      if (res) {
        this.showToastSuccess('Success', 'Add list group successfully!')
        this.refetchData()
        this.selectedGroups = []
        this.$refs.group.hide()
      }
    },
    handleShowListGroup() {
      this.$refs.group.show()
    },
    async  handlePlusPage() {
      if (this.page < this.totalPage) {
        this.page++
      }
    },
    async  handleMinusPage() {
      if (this.page > 1) {
        this.page--
      }
    },
    handleShowModalDeleteGroup(item) {
      this.groupDeleteId = item.groupId
      this.$refs['delete-group'].show()
    },
    async handleDeleteGroup() {
      const res = await this.deleteCompGroup({
        competitionId: this.$route.params.id,
        groupIds: this.groupDeleteId,
      })
      if (res) {
        this.showToastSuccess('Success', 'Remove group successfully!')
        this.refetchData()
        this.groupDeleteId = null
      }
    },
    disabledSelectedGroup(item) {
      return this.listGroupOfComp.some(i => i.groupId === item.groupId)
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const refGroupTable = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'groupId', label: 'COMP RACE EVENT ID' },
      { key: 'name' },
      { key: 'code' },
      { key: 'totalUsers', label: 'total users' },
      { key: 'createdTime' },
      { key: 'delete' },
    ]

    const perPage = ref(10)
    const totalGroup = ref(0)
    const totalRaces = ref(0)
    const currentPage = ref(1)

    const perPageOptions = [10, 25, 50, 100]

    const refetchData = () => {
      refGroupTable.value.refresh()
    }

    const dataMeta = computed(() => {
      const localItemsCount = refGroupTable.value ? refGroupTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalGroup.value,
      }
    })

    watch([currentPage, perPage], () => {
      refetchData()
    })

    return {
      perPage,
      totalGroup,
      dataMeta,

      formatDate,
      formatDateTimeLocal,
      showToastError,
      tableColumns,
      currentPage,
      totalRaces,
      refGroupTable,
      refetchData,

      perPageOptions,

      formatTime,

      showToastSuccess,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .id-column {
    max-width: 150px;
    word-break: break-word;
  }
  </style>
